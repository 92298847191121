import isEqual from 'lodash/isEqual'

export default defineNuxtRouteMiddleware((to, from) => {
  if (process.server) return

  const { $normalizePath, $analytics } = useNuxtApp()

  const isSamePathDifferentQuery =
    $normalizePath(from.path) === $normalizePath(to.path) &&
    !isEqual(from.query, to.query)

  if (!(isSamePathDifferentQuery || to.meta.sendDefaultAmplitude === false)) {
    $analytics.amplitude('page_view')
  }
})
