import { createVNode, render } from 'vue'
import type { Component, ComponentInternalInstance, VNode } from 'vue'
import type { NuxtApp } from 'nuxt/app'
import DialogTemplate from './templates/dialog.vue'
import type { AnyFunction, AsyncComponent, PassedProps, Options } from './types'

const DefaultContentComponent: Component = {
  setup(_props, { slots }) {
    return () => h('div', slots.default?.())
  }
}

const defaultOptions: Options = {
  autoOpen: true,
  destroyAfterClose: true,

  fetchData: undefined,
  dataKey: 'data',

  content: '',
  contentProps: {},

  loading: undefined,
  loadingProps: {},

  wrapper: undefined,
  wrapperProps: {},

  zIndex: 100,
  id: undefined,
  blur: true,
  closeOnEsc: true,
  closeByOutsideClick: true,
  closeOnChangeRoute: true,

  onClose: undefined,
  onClosed: undefined
}

export class Dialog {
  app: NuxtApp['vueApp'] | null = null
  options: Options
  $element: HTMLElement | null = null
  vNode: VNode | null = null
  data: any = null

  constructor(vueApp: NuxtApp['vueApp'], options: Options = {}) {
    this.app = vueApp
    this.options = Object.assign({}, defaultOptions, options)

    this.render()

    if (this.options.autoOpen) {
      this.open()
    }
  }

  open() {
    this.checkVNodeComponentMethod('open')

    this.fetchData()

    this.vNode.component.exposed.open()
  }

  async fetchData() {
    try {
      this.checkVNodeComponent()

      const asyncComponents = this.getAsyncComponentsForPreload()
      this.vNode.component.props.loading = true

      if (asyncComponents.length) {
        await preloadAsyncComponents(asyncComponents)
      }

      if (typeof this.options.fetchData === 'function') {
        this.data = await this.options.fetchData()
      }

      this.vNode.component.props.loading = false
    } catch (error) {
      console.error('Dialog failed fetch', error)
      this.close()
    }
  }

  close(...args: any[]) {
    this.checkVNodeComponentMethod('close')

    this.vNode.component.exposed.close(...args)
  }

  destroy() {
    this.checkVNode()

    if (!this.$element) {
      throw new ReferenceError('Element is not defined')
    }

    render(null, this.$element)
    this.$element.remove()

    this.app = null
    ;(this as this).vNode = null
  }

  private getAsyncComponents() {
    return [this.options.content, this.options.wrapper].filter(
      isAsyncComponentWrapper
    )
  }

  private getAsyncComponentsForPreload() {
    return this.getAsyncComponents().filter(
      component => !component.__asyncResolved
    )
  }

  private render() {
    if (this.vNode) return this

    if (!this.app) {
      throw new ReferenceError('App is not defined')
    }

    this.$element = document.createElement('div')
    const target = document.querySelector('body')

    if (!target) {
      throw new ReferenceError('Target is not defined')
    }

    const props = {
      loading: !!this.options.fetchData,
      zIndex: this.options.zIndex,
      id: this.options.id,
      blur: this.options.blur,
      closeOnEsc: this.options.closeOnEsc,
      closeOnChangeRoute: this.options.closeOnChangeRoute,
      closeByOutsideClick: this.options.closeByOutsideClick,
      onClosed: (...args: any[]) => {
        if (typeof this.options.onClosed === 'function') {
          this.options.onClosed(...args)
        }
        if (this.options.destroyAfterClose) {
          this.destroy()
        }
      },
      onClose: (...args: any[]) => {
        if (typeof this.options.onClose === 'function') {
          this.options.onClose(...args)
        }
      }
    }

    this.vNode = createVNode(DialogTemplate, props, {
      ...(this.options.loading
        ? { loading: () => this.createLoadingComponent() }
        : {}),
      content: () => this.createContentComponent()
    })

    target.appendChild(this.$element)
    this.vNode.appContext = this.app._context

    render(this.vNode, this.$element)

    return this
  }

  private createContentComponent() {
    const content =
      !this.options.content || typeof this.options.content === 'string'
        ? createVNode(DefaultContentComponent, undefined, {
            default: () => this.options.content
          })
        : createVNode(this.options.content, {
            ...getProps(this.options.contentProps),
            ...(this.options.dataKey
              ? { [this.options.dataKey]: this.data }
              : {}),
            onRefresh: () => this.fetchData(),
            onClose: (...args: any[]) => this.close(...args)
          })

    return this.options.wrapper
      ? createVNode(
          this.options.wrapper,
          {
            ...getProps(this.options.wrapperProps),
            onClose: (...args: any[]) => this.close(...args)
          },
          {
            default: () => content
          }
        )
      : content
  }

  private createLoadingComponent() {
    if (!this.options.loading) {
      throw new ReferenceError('Loading component is not defined')
    }

    const props = getProps(this.options.loadingProps)
    return createVNode(this.options.loading, props)
  }

  private checkVNode(): asserts this is { vNode: VNode } {
    if (!this.vNode) {
      throw new ReferenceError('Dialog not created')
    }
  }

  private checkVNodeComponent(): asserts this is {
    vNode: VNode & { component: ComponentInternalInstance }
  } {
    this.checkVNode()

    if (!this.vNode.component) {
      throw new ReferenceError('Component is not defined')
    }
  }

  private checkVNodeComponentMethod<T extends string>(
    method: T
  ): asserts this is {
    vNode: VNode & {
      component: ComponentInternalInstance & {
        exposed: { [method in T]: AnyFunction }
      }
    }
  } {
    this.checkVNodeComponent()

    if (!this.vNode.component.exposed) {
      throw new ReferenceError('Component does not expose anything')
    }

    if (typeof this.vNode.component.exposed[method] !== 'function') {
      throw new TypeError(`"${method}" is not a function`)
    }
  }

  static setDefaultOptions(options: Options = {}) {
    Object.assign(defaultOptions, options)
  }
}

function preloadAsyncComponents(asyncComponents: AsyncComponent[]) {
  return Promise.all(
    asyncComponents.map(component => component.__asyncLoader?.())
  )
}

function getProps(props?: PassedProps): Record<string, any> {
  return (typeof props === 'function' ? props() : props) ?? {}
}

function isAsyncComponentWrapper(
  component?: string | Component | null
): component is AsyncComponent {
  return typeof component === 'string'
    ? false
    : component?.name === 'AsyncComponentWrapper'
}
