export default function (sdk) {
  return {
    async mounted(el, binding) {
      const host = await getHost(binding)
      const config = getConfigFromBinding(binding.value)
      if (!config.disabled) {
        subscribe(host, config.channel)
      }
    },

    async unmounted(el, binding) {
      const host = await getHost(binding)
      const config = getConfigFromBinding(binding.value)
      if (!config.disabled) {
        unsubscribe(host, config.channel)
      }
    },

    async updated(el, binding) {
      const host = await getHost(binding)
      const config = getConfigFromBinding(binding.value)
      const prevConfig = getConfigFromBinding(binding.oldValue)
      if (config.channel !== prevConfig.channel) {
        if (!prevConfig.disabled) {
          unsubscribe(host, prevConfig.channel)
        }
        if (!config.disabled) {
          subscribe(host, config.channel)
        }
      } else {
        if (config.disabled && !prevConfig.disabled) {
          unsubscribe(host, config.channel)
        } else if (!config.disabled && prevConfig.disabled) {
          subscribe(host, config.channel)
        }
      }
    }
  }

  function subscribe(host, channelName) {
    if (!channelName) return
    host?.subscribe(channelName)
  }

  function unsubscribe(host, channelName) {
    if (!channelName) return
    host?.unsubscribe(channelName)
  }

  async function getHost({ arg }) {
    const [moduleName, fnArg] = (arg ?? 'common').split(':')
    const { getWSInstance } = await sdk.module(moduleName)

    return getWSInstance(fnArg)
  }

  function getConfigFromBinding(bindingValue) {
    return {
      channel:
        bindingValue && typeof bindingValue === 'object'
          ? bindingValue.channel
          : bindingValue,
      disabled: bindingValue?.disabled ?? false
    }
  }
}
