<template>
  <component
    :is="tag"
    :class="[$style.container, source.fullWidth && $style.fullWidth]"
    :id="id"
  >
    <component :class="$style.title" :is="titleTag" v-if="source.title">
      <span :class="$style.t">{{ source.title }}</span>
    </component>
    <div :class="$style.content">
      <div :class="$style.sections">
        <document-content
          v-for="(section, i) in source.sections"
          :key="i"
          :source="section"
        />
      </div>
    </div>
  </component>
</template>

<script>
export default {
  name: 'DocumentContentSection',
  components: {},
  props: {
    source: {
      type: Object,
      required: true
    }
  },
  computed: {
    titleTag() {
      return 'h2'
    },
    id() {
      return this.source.id || ''
    },
    tag() {
      return this.source.tag || 'section'
    }
  }
}
</script>

<style lang="scss" module>
.container {
  a {
    color: var(--app-color-light-green);
  }
}
.container + .container {
  padding-top: 10em;
}
.title {
  border-bottom: 1px solid rgba(#c0c0c0, 0.5);
  padding-bottom: 2.3em;
  padding-top: 4.5em;
  //top: 6em;
  top: 0;
  text-transform: uppercase;
  margin-bottom: 4.4em;
  position: sticky;
  background-color: var(--t-app-bg);
  .t {
    font-size: 3em;
    line-height: 140%;
    font-weight: 500;
    color: #fff;
  }
}
.content {
  display: flex;
  justify-content: flex-end;
  padding-right: 8em;
  p + p {
    margin-top: 3em;
  }
  @include down(md) {
    padding-right: 0;
  }
}
.sections {
  max-width: 50%;
  @include down(md) {
    max-width: 100%;
    overflow: scroll;
    @include hide-scrollbar;
  }
  & > table {
    margin-left: -100%;
    @include down(md) {
      margin-left: 0;
    }
  }
}
.fullWidth {
  .content {
    display: block;
  }
  .sections {
    max-width: 100%;
  }
}
</style>
