import { defineNuxtPlugin } from '#app'
import Share from './share'

export default defineNuxtPlugin(() => {
  const instance = new Share()

  return {
    provide: {
      share: instance
    }
  }
})
