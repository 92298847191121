import * as Sentry from '@sentry/nuxt'

const isDev = process.env.NODE_ENV === 'development'

if (!isDev) {
  const environment = process.env.NUXT_PUBLIC_SENTRY_ENVIRONMENT
  const apiBase = process.env.NUXT_API_BASE
  const tracePropagationTargets = []
  if (apiBase) {
    tracePropagationTargets.push(apiBase)
  }

  Sentry.init({
    dsn: 'https://d4b3c8b29be09c6e3ae6987aba23dae2@sentry.lvl.io/3',
    environment,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration()
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets,
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  })
}
