<template>
  <transition :name="$style[transitionName]">
    <div
      :class="classes"
      :id="id"
      v-if="showContent"
      :style="{ zIndex: zIndex + 1 }"
      v-scroll-lock
      @click.self="onContainerClick"
    >
      <div :class="$style.content" @mouseenter="hovered = true">
        <slot name="content" />
      </div>
    </div>
  </transition>

  <div
    v-if="showLoading"
    :style="loadingStyle"
    :class="$style.loading"
    v-scroll-lock
  >
    <span v-if="!$slots.loading">Loading...</span>
    <slot name="loading" />
  </div>

  <transition :name="$style.fade" @after-leave="$emit('closed')">
    <div
      v-if="popupShow"
      :class="[$style.overlay, $props.blur && $style.blur]"
      :style="{ zIndex: zIndex }"
    ></div>
  </transition>
</template>

<script lang="ts" setup>
// import { disablePageScroll, enablePageScroll } from 'scroll-lock'

const props = withDefaults(
  defineProps<{
    loading?: boolean
    closeOnEsc?: boolean
    closeByOutsideClick?: boolean
    closeOnChangeRoute?: boolean
    transitionName?: 'slide-down' | 'show'
    blur?: boolean
    zIndex?: number
    id?: string
  }>(),
  {
    loading: false,
    closeOnEsc: true,
    closeByOutsideClick: true,
    closeOnChangeRoute: true,
    transitionName: 'show',
    blur: true,
    zIndex: 100,
    id: undefined
  }
)

const emit = defineEmits<{
  close: [...args: any[]]
  closed: [...args: any[]]
}>()

const route = useRoute()

const popupShow = ref(false)
const hovered = ref(false)

const showContent = computed(() => !props.loading && popupShow.value)
const showLoading = computed(() => props.loading && popupShow.value)

const loadingStyle = computed(() => ({
  zIndex: props.zIndex + 1
}))

const cssModule = useCssModule()

const classes = computed(() => [
  cssModule.container,
  hovered.value && cssModule.hovered,
  props.closeByOutsideClick && cssModule.closeByOutsideClick
])

const open = () => {
  popupShow.value = true
  // disablePageScroll(el)
}

const close = (...args: any[]) => {
  if (popupShow.value) {
    popupShow.value = false
    emit('close', ...args)
    // enablePageScroll(el)
  }
}

const onEscEvent = (event: KeyboardEvent) =>
  event.key === 'Escape' && popupShow.value && close()
const onContainerClick = () => {
  if (props.closeByOutsideClick) {
    close()
  }
}

watch(
  () => route.path,
  () => {
    if (props.closeOnChangeRoute) {
      close()
    }
  }
)

onMounted(() => {
  if (props.closeOnEsc) {
    window.addEventListener('keyup', onEscEvent)
  }
})

onBeforeUnmount(() => {
  if (props.closeOnEsc) {
    window.removeEventListener('keyup', onEscEvent)
  }
})

defineExpose({ open, close })
</script>

<style lang="scss" module>
.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  z-index: 101;
  scroll-behavior: smooth;
  overflow: auto;
  display: grid;
  align-items: center;
  justify-content: center;
  -webkit-overflow-scrolling: touch;
  scrollbar-color: transparent transparent;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.container.hovered.closeByOutsideClick:hover .content {
  transform: scale(0.95, 0.95);
}
.closeByOutsideClick {
  cursor: pointer;
}
.content {
  position: relative;
  z-index: 2;
  display: inline-block;
  cursor: default;
  transition: transform 0.6s cubic-bezier(0.785, 0.135, 0.15, 1);
  &:hover {
    transform: scale(1, 1) !important;
  }
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: var(--dialog-overlay-bg);
  z-index: 100;
  &.blur {
    backdrop-filter: var(--dialog-overlay-backdrop-filter);
  }
}
.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
}

.slide {
  &:global(-enter-active),
  &:global(-leave-active) {
    transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 1);
  }

  &:global(-enter-from) {
    transform: translate(100%, 0);
  }
  &:global(-leave-to) {
    transform: translate(-100%, 0);
  }
}

.show {
  &:global(-enter-active),
  &:global(-leave-active) {
    transition:
      transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 1),
      opacity 0.5s cubic-bezier(0.785, 0.135, 0.15, 1);
  }

  &:global(-leave-to),
  &:global(-enter-from) {
    opacity: 0;
    transform: scale(0.4);
    @include down(md) {
      transform: translate(0, 100%);
    }
  }
}

.slide-down {
  &:global(-enter-active),
  &:global(-leave-active) {
    transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 1);
  }

  &:global(-leave-to),
  &:global(-enter) {
    transform: translate(0, 100%);
  }
}

.fade {
  &:global(-enter-active),
  &:global(-leave-active) {
    transition: opacity 0.3s ease;
  }

  &:global(-leave-to),
  &:global(-enter-from) {
    opacity: 0;
  }
}
</style>
