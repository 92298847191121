<template>
  <div :class="$style.container">
    <img :src="source.src" alt="" />
  </div>
</template>

<script>
export default {
  name: 'DocumentContentImage',
  props: {
    source: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" module>
.container {
  img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }
}
</style>
