<template>
  <component :is="component" aria-hidden="true" />
</template>

<script lang="ts" setup>
import { computed, defineAsyncComponent } from 'vue'
import type { IconComponent } from '~/types'

defineOptions({
  name: 'UiIcon'
})

const props = defineProps<{
  name: string
}>()

const component = computed(() => {
  const fileName = props.name // we have to declare it explicitly, otherwise the computed won't be reactive
  return defineAsyncComponent<IconComponent>(
    () => import(`./icons/${fileName}.svg`)
  )
})
</script>
