<template>
  <table :class="$style.table" cellpadding="0" cellspacing="0">
    <thead>
      <tr>
        <th v-for="(item, i) in source.head" :key="i" :class="$style.head">
          <document-content :source="item" />
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(row, i) in source.rows" :key="i" :class="$style.row">
        <td v-for="(item, j) in row" :key="j" :class="$style.row">
          <document-content :source="item" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'DocumentContentTable',
  components: {},
  props: {
    source: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" module>
.table {
  min-width: 100%;
  margin-bottom: 4em;
  th {
    border-bottom: 1px solid rgba(#c0c0c0, 0.2);
  }
  tr:first-child td {
    padding-top: 2em;
  }
  th,
  td {
    padding: 1em 2em;
    text-align: left;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
}
.head {
  .t {
  }
}
.row {
}
</style>
