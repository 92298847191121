<template>
  <div>
    <div :class="$style.guide" :style="guideStyle">
      <transition :name="popoverAnimationName">
        <app-guide-popover
          v-if="showPopover"
          ref="popover"
          :visible="visible"
          :tail="tail"
          :theme="step.theme"
          :size="step.size"
          :class-name="step.className"
          :title="step.title"
          :text="step.text"
          :duration="step.duration"
          :buttons="step.buttons"
          :steps-count="state.steps"
          :step-index="state.step"
          :show-steps-counter="options.showStepsCounter"
          :amplitude="step.amplitude"
          @next="onNext"
          @skip="onSkip"
        />
      </transition>
    </div>

    <transition :name="$style.fade">
      <app-guide-overlay
        v-if="showPopover"
        :rect="overlayRect"
        :options="step.overlay"
      />
    </transition>
  </div>
</template>

<script>
import AppGuidePopover from './popover'
import AppGuideOverlay from './overlay'
import { disablePageScroll, enablePageScroll } from 'scroll-lock'

export default {
  name: 'AppGuideComponent',
  components: {
    AppGuidePopover,
    AppGuideOverlay
  },
  data() {
    return {
      guidePromise: null,
      resolve: null,
      options: null,
      position: { x: 0, y: 0 },
      overlayRect: { x: 0, y: 0, width: 0, height: 0, wwidth: 0, wheight: 0 },
      steps: [],
      index: 0,
      step: null,
      tail: 'top',
      opacity: 0,
      visible: false,
      showPopover: false,
      showTimeout: null,
      raf: null,
      state: {
        steps: 0,
        step: 0,
        active: false,
        started: false,
        finished: false
      }
    }
  },
  computed: {
    guideStyle() {
      return {
        zIndex: 1001,
        transform: `matrix(1,0,0,1,${this.position.x},${this.position.y})`
      }
    },
    popoverAnimationName() {
      return this.$style[this.step?.position || 'bottom']
    }
  },
  methods: {
    create(steps = [], options = {}) {
      this.steps = steps
      this.state.started = false
      this.state.steps = this.steps.length
      this.state.finished = false
      this.state.active = true

      this.options = Object.assign(
        {
          autostart: true,
          showStepsCounter: true
        },
        options
      )

      if (this.options.autostart) {
        this.open()
      }

      return this.guidePromise
    },
    openId(id) {
      const index = this.steps.findIndex(item => item.id === id)
      if (index > -1) {
        this.open(index)
      }
    },
    open(index = 0) {
      if (!this.steps.length) {
        return
      }

      this.initStep(index)

      if (!this.state.started) {
        this.state.started = true
        this.loop()
      }
    },
    stop() {
      clearTimeout(this.showTimeout)
      if (this.step.scroll.lock) {
        this.enablePageScroll(true)
      }
      this.index = 0
      this.step = null
      this.showPopover = false
      this.showTimeout = null
      this.visible = false
      this.state.active = false
      this.stopLoop()

      this.resolve(this.state)
    },
    next() {
      this.onNext()
    },
    stopLoop() {
      cancelAnimationFrame(this.raf)
    },
    onNext() {
      this.initStep(this.index + 1)
    },
    onSkip() {
      this.stop()
    },
    isActive() {
      return this.state.active
    },
    loop() {
      if (!this.step) {
        return
      }
      const element =
        this.step.element &&
        (this.step.element instanceof HTMLElement
          ? this.step.element
          : document.querySelector(this.step.element))

      if (element || !this.step.element) {
        this.showStep()
        this.visible = !!this.$refs.popover
        this.calcPosition(element, this.step)
        // } else if (this.showPopover) {
        //   this.stop()
      }

      this.raf = requestAnimationFrame(() => this.loop())
    },
    initStep(index) {
      if (this.step && this.step.scroll.lock) {
        this.enablePageScroll(true)
      }

      const step = this.steps[index]
      this.showPopover = false
      this.showTimeout = null

      if (step) {
        this.state.step = index + 1
        this.index = index
        this.step = this.createStep(step)
        if (this.step.scroll.lock) {
          this.enablePageScroll(false)
        }
      } else {
        this.state.finished = true
        this.stop()
      }
    },
    createStep(options) {
      const result = Object.assign(
        {
          position: options.element ? 'bottom' : 'center',
          theme: 'primary',
          size: 'small',
          delay: 0,
          title: '',
          text: '',
          className: '',
          duration: 0,
          buttons: []
        },
        options
      )
      result.offset = Object.assign(
        { left: 0, top: 0, right: 0, bottom: 0 },
        options.offset
      )
      result.overlay = Object.assign(
        { padding: 0, opacity: 0.5, pointerEvents: 'painted' },
        options.overlay
      )
      result.scroll = Object.assign(
        { focus: false, lock: false },
        options.scroll
      )
      result.buttons = result.buttons.map(button =>
        Object.assign(
          {
            text: 'Go it',
            emit: 'next',
            cb: null,
            theme: 'default',
            href: '',
            align: 'left'
          },
          button
        )
      )
      return result
    },
    calcPosition(element, step) {
      const popover = this.$refs.popover
      if (popover) {
        const focusRect = element
          ? element.getBoundingClientRect()
          : {
              top: window.innerHeight / 2,
              bottom: window.innerHeight / 2,
              left: innerWidth / 2,
              right: innerWidth / 2,
              width: 1,
              height: 1
            }
        const popoverRect = popover
          ? popover.$el.getBoundingClientRect()
          : { left: 0, top: 0, width: 0, height: 0 }
        const popoverComputedStyle = window.getComputedStyle(popover.$el)
        let x = 0
        let y = 0

        if (step.position === 'top') {
          x = focusRect.left + (focusRect.width - popoverRect.width) / 2
          y = focusRect.top - popoverRect.height
          this.tail = 'bottom'
        } else if (step.position === 'bottom') {
          x = focusRect.left + (focusRect.width - popoverRect.width) / 2
          y = focusRect.bottom
          this.tail = 'top'
        } else if (step.position === 'left') {
          x = Math.max(focusRect.left - popoverRect.width, 0)
          y = focusRect.top + (focusRect.height - popoverRect.height) / 2
          this.tail = 'right'
        } else if (step.position === 'right') {
          x = Math.min(focusRect.right, window.innerWidth)
          y = focusRect.top + (focusRect.height - popoverRect.height) / 2
          this.tail = 'left'
        } else if (step.position === 'center') {
          x = focusRect.left - popoverRect.width / 2
          y = focusRect.top - popoverRect.height / 2
          this.tail = 'center'
        }

        this.overlayRect.x = focusRect.left - step.overlay.padding
        this.overlayRect.y = focusRect.top - step.overlay.padding
        this.overlayRect.width = focusRect.width + step.overlay.padding * 2
        this.overlayRect.height = focusRect.height + step.overlay.padding * 2
        this.overlayRect.wwidth = window.innerWidth
        this.overlayRect.wheight = window.innerHeight
        this.overlayRect.r = 0

        x += this.step.offset.left
        y += this.step.offset.top

        const topOverflow = y < -parseFloat(popoverComputedStyle.paddingTop)
        const bottomOverflow =
          y >
          window.innerHeight +
            parseFloat(popoverComputedStyle.paddingBottom) -
            popoverRect.height

        const leftOverflow = x < -parseFloat(popoverComputedStyle.paddingLeft)
        const rightOverflow =
          x >
          window.innerWidth +
            parseFloat(popoverComputedStyle.paddingRight) -
            popoverRect.width

        if (topOverflow || bottomOverflow || leftOverflow || rightOverflow) {
          this.tail = 'center'
        }

        x = Math.min(x, window.innerWidth - popoverRect.width)
        y = Math.min(y, window.innerHeight - popoverRect.height)

        x = Math.max(x, 0)
        y = Math.max(y, 0)

        if (this.position.x !== x) {
          this.position.x = x
        }
        if (this.position.y !== y) {
          this.position.y = y
        }

        // if (this.step.scroll.focus) {
        //   const scrollTop = this.overlayRect.y + document.scrollingElement.scrollTop - window.innerHeight / 2 + this.overlayRect.height / 2
        //   console.log('scrollTop', scrollTop)
        //   // if (scrollTop !== document.scrollingElement.scrollTop) {
        //   //   document.scrollingElement.scrollTop = scrollTop
        //   // }
        //   // this.scrolled = true
        // }
      }
    },
    showStep() {
      if (!this.showTimeout) {
        this.showTimeout = setTimeout(() => {
          this.showPopover = true
        }, this.step.delay)
      }
    },
    enablePageScroll(value = true) {
      if (value) {
        enablePageScroll()
      } else {
        disablePageScroll()
      }
    }
  },
  mounted() {
    this.guidePromise = new Promise((resolve, reject) => {
      this.resolve = resolve
    })
  },
  beforeUnmount() {
    this.stop()
  }
}
</script>

<style lang="scss" module>
.guide {
  @include appSize;
  position: fixed;
  top: 0;
  left: 0;
}

.fade {
  &:global(-enter-active),
  &:global(-leave-active) {
    transition: opacity 0.4s ease;
  }

  &:global(-leave-to),
  &:global(-enter-from) {
    opacity: 0;
  }
}
.bottom,
.top,
.left,
.right,
.center {
  &:global(-enter-active),
  &:global(-leave-active) {
    transition:
      transform 0.4s ease,
      opacity 0.4s ease;
  }
  &:global(-leave-to),
  &:global(-enter-from) {
    opacity: 0;
  }
}
.bottom {
  &:global(-leave-to),
  &:global(-enter-from) {
    transform: translate(0, 1em);
  }
}
.top {
  &:global(-leave-to),
  &:global(-enter-from) {
    transform: translate(0, -1em);
  }
}
.left {
  &:global(-leave-to),
  &:global(-enter-from) {
    transform: translate(1em, 0);
  }
}
.right {
  &:global(-leave-to),
  &:global(-enter-from) {
    transform: translate(-1em, 0);
  }
}
</style>
