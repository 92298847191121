import appConfig from '@/app.config'
import type { Options } from './types'

export const normalizePath = (
  path = '/',
  { useHost = false, locale }: Options = {
    useHost: false,
    locale: appConfig.defaultLocale
  }
) => {
  const localePath = appConfig.defaultLocale === locale ? '' : '/' + locale
  path = localePath + appConfig.basePath + path.replace(appConfig.basePath, '')
  const url = new URL(path, 'https://' + appConfig.host)
  const fullPath = url.pathname.replace(/\/$/, '') + '/' + url.search + url.hash
  return useHost ? url.origin + fullPath : fullPath
}
