import pick from 'lodash/pick'
import isEmpty from 'lodash/isEmpty'

const UTM_PARAMS = [
  'offer_id',
  'partner_id',
  'landing_id',
  'sub_1',
  'sub_2',
  'utm_medium',
  'utm_source',
  'utm_campaign',
  'utm_content',
  'utm_term'
]

export default defineNuxtRouteMiddleware((to, from) => {
  if (process.server) {
    return
  }

  const { $auth } = useNuxtApp()

  if ($auth.account) {
    return
  }

  const fromUTMParams = pick(from.query, UTM_PARAMS)
  const toUTMParams = pick(to.query, UTM_PARAMS)

  if (!isEmpty(fromUTMParams) && isEmpty(toUTMParams)) {
    return navigateTo({ ...to, query: { ...to.query, ...fromUTMParams } })
  }
})
