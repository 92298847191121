import { defineStore } from 'pinia'
import { useApiClient } from '@/composables/api-client'
import { useStorage } from '@vueuse/core'

const storageKey = 'dphub/activator/activation/token'

export const useActivatorStore = defineStore('activator', {
  state: () => ({
    token: useStorage(storageKey, null)
  }),
  actions: {
    async activation() {
      if (this.token) {
        const client = useApiClient()
        try {
          const data = await client.post(
            `/api/v1/store/activator/activate/${this.token}`
          )
          return data
        } catch (e) {
        } finally {
          this.token = null
        }
      }
    },
    setToken(token) {
      this.token = token
    }
  },
  hydrate(state, initialState) {
    // in this case we can completely ignore the initial state since we
    // want to read the value from the browser
    state.token = useStorage(storageKey, null)
  }
})
