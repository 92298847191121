<template>
  <component :is="component" :source="source" />
</template>

<script>
import DocumentContentSection from './components/section'
import DocumentContentText from './components/text'
import DocumentContentLink from './components/link'
import DocumentContentList from './components/list'
import DocumentContentTable from './components/table'
import DocumentContentTexts from './components/texts'
import DocumentContentParagraph from './components/paragraph'
import DocumentContentSubtitle from './components/subtitle'
import DocumentContentImage from './components/image'

export default {
  name: 'DocumentContent',
  components: {
    DocumentContentSubtitle,
    DocumentContentTexts,
    DocumentContentTable,
    DocumentContentList,
    DocumentContentLink,
    DocumentContentText,
    DocumentContentSection,
    DocumentContentParagraph,
    DocumentContentImage
  },
  props: {
    source: {
      type: Object,
      required: true
    }
  },
  computed: {
    component() {
      return `document-content-${this.source.type}`
    }
  }
}
</script>
