import type { NuxtApp } from '#app'
import { useAccountStore } from '~/stores/account'
import { createSDK } from 'lvl-io-sdk'

export default defineNuxtPlugin(nuxtApp => {
  type __NuxtApp = typeof nuxtApp & Pick<NuxtApp, '$i18n' | '$normalizePath'>

  const { hooks, $i18n, $normalizePath } = nuxtApp as __NuxtApp
  const appConfig = useAppConfig()
  const runtimeConfig = useRuntimeConfig()

  const accountStore = useAccountStore()

  const sdk = createSDK(runtimeConfig.public.APP_PUBLIC_KEY, {
    projectId: parseInt(runtimeConfig.public.APP_PROJECT_ID, 10),
    host: import.meta.server
      ? runtimeConfig.apiBase
      : runtimeConfig.public.apiBase,
    api: {
      language: $i18n.locale.value,
      onResponseError: (error, id) => {
        hooks.callHook('api-client:error' as any, error, id)
      }
    }
  })

  sdk.setModuleOptions('auth', {
    callbackPath: appConfig.auth.callbackPath,
    navigateTo,
    normalizePath: $normalizePath,
    onFetchAccount: (account: any) => {
      if (account) {
        accountStore.account = account
      }
    },
    accountRequestQuery: {
      expand: ['coins', 'profile', 'styles', 'scopes'],
      fields: [
        'id',
        'email',
        'uid',
        'username',
        'avatar',
        'coins',
        'profile.agree',
        'steam_id',
        'created_at'
      ]
    }
  })

  sdk.setModuleOptions('common', {
    hostWS: runtimeConfig.public.wsBaseHost
  })

  return {
    provide: {
      sdk
    }
  }
})
