<template>
  <div :class="$style.container">
    <div>
      <h1 :class="$style.title">
        <span v-if="error.statusCode === 404">{{ t('notFound') }}</span>
        <span v-else>{{ t('error') }}</span>
      </h1>

      <div :class="$style.body" v-if="isDev">
        <div :class="$style.headline">
          <span :class="$style.t"
            >CODE: <span>{{ error.statusCode }}</span></span
          >
        </div>
        <div :class="$style.headline">
          <span :class="$style.t"
            >URL: <span>{{ error.url }}</span></span
          >
        </div>
        <div :class="$style.message">
          <span :class="$style.t">{{ error.message }}</span>
        </div>
        <div :class="$style.stack">
          <span :class="$style.t" v-html="error.stack"></span>
        </div>
      </div>

      <div :class="$style.actions">
        <ui-button
          to="/"
          theme="accent"
          size="medium"
          fill
          v-if="error.statusCode === 404"
          >{{ t('goToHome') }}</ui-button
        >
        <ui-button @click="reload" theme="accent" size="medium" fill v-else>{{
          t('reload')
        }}</ui-button>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "notFound": "Page not found",
    "error": "An error occurred",
    "goToHome": "Home page",
    "reload": "Reload"
  },
  "ru": {
    "notFound": "Страница не найдена",
    "error": "Упс... возникла ошибка!",
    "goToHome": "На главную",
    "reload": "Перезагрузить страницу"
  }
}
</i18n>

<script lang="ts" setup>
// https://nuxt.com/docs/getting-started/error-handling

import { useI18n } from '#imports'
import UiButton from '~/components/ui/button/index.vue'

interface HandledError {
  url: string
  statusCode: number
  statusMessage: string
  message: string
  description: string
  data?: any
  stack?: string
}

defineProps<{
  error: HandledError
}>()

const { t } = useI18n()

const isDev = process.env.NODE_ENV === 'development'

const reload = () => {
  location.reload()
  // clear error and redirect to home page
  // const handleError = () => clearError({ redirect: '/' });
}
</script>

<style module lang="scss">
.container {
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.title {
  margin-bottom: 4em;
  span {
    font-size: 4em;
    font-family: var(--primary-font), sans-serif;
    font-style: var(--primary-font-style);
    text-transform: uppercase;
    font-weight: 700;
    @include down(sm) {
      font-size: 3em;
    }
  }
}
.body {
  padding: 0 4em;
  max-width: 90em;
  margin-bottom: 4em;
  text-align: left;
}
.headline {
  margin-bottom: 2em;
  .t {
    font-size: 2.4em;
  }
}
.message {
  margin-bottom: 2em;
  .t {
    font-size: 1.8em;
  }
}
.stack {
  .t {
    font-size: 1.6em;
  }
}
.actions {
  text-align: center;
}
</style>
