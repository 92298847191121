<template>
  <div :class="$style.container">
    <div :class="$style.title">
      <span :class="$style.t">{{ source.title }}</span>
    </div>
    <div>
      <content-text :source="source" />
    </div>
  </div>
</template>

<script>
import ContentText from './text'
export default {
  name: 'DocumentContentSubtitle',
  components: {
    ContentText
  },
  props: {
    source: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" module>
.container {
  margin-top: 4em;
}
.title {
  margin-bottom: 1em;
  .t {
    font-size: 1.8em;
    line-height: 130%;
    text-decoration: underline;
  }
}
</style>
