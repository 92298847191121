import { useAccountStore } from '@/stores/account'

export default function () {
  const accountStore = useAccountStore()

  const defaultOptions = {
    event: 'click',
    enabled: true
  }

  let globalEvents = []
  let globalOptions = {}

  const handler = async e => {
    if (!accountStore.isAuthorized) {
      e.stopPropagation()
      e.preventDefault()
      if (
        globalOptions.execute &&
        typeof globalOptions.execute === 'function'
      ) {
        await globalOptions.execute(e)
      } else {
        const { $appClient } = useNuxtApp()
        await $appClient.showSignIn()
      }
      e.target.dispatchEvent(e)
    }
  }

  return {
    mounted(el, binding) {
      globalOptions = Object.assign({}, defaultOptions, binding.value)
      if (globalOptions.enabled) {
        globalEvents = Array.isArray(globalOptions.event)
          ? globalOptions.event
          : [globalOptions.event]

        globalEvents.forEach(eventType => {
          el.addEventListener(eventType, handler, true)
        })
      }
    },
    unmounted(el) {
      globalEvents.forEach(eventType => {
        el.removeEventListener(eventType, handler, true)
      })
    }
  }
}
