import { useGuideStore } from '~/stores/common/guide'
import {
  config as guideConfig,
  options as guideOptions
} from '~/_plugins/guide/configs/app'

// import {
//   config as guideBonusFabConfig,
//   options as guideBonusFabOptions
// } from '@/_plugins/guide/configs/bonus/fab'
// import {
//   config as guideBonusTabConfig,
//   options as guideBonusTabOptions
// } from '@/_plugins/guide/configs/bonus/tab'

export default defineNuxtRouteMiddleware((to, from) => {
  if (process.server) return

  const { $sdk, $guide, $normalizePath } = useNuxtApp()

  $sdk
    .module('auth')
    .then(({ getAccount }) => getAccount())
    .then((account: any) => {
      if (account) {
        const guideStore = useGuideStore()

        if (
          !$guide.active &&
          window.innerWidth > 570 &&
          !guideStore.guideIsFinished('welcome') &&
          [$normalizePath('/tasks')].includes(to.path)
        ) {
          guideStore.setLocalStateAsFinished('welcome')
          return $guide.create(guideConfig, guideOptions)
        }
      }
    })

  // function showBonusGuide() {
  //   if (!$guide.active && !guideStore.guideIsFinished('bonusFabTab')) {
  //     guideStore.setLocalStateAsFinished('bonusFabTab')
  //     if (window.innerWidth > 570) {
  //       if (guideStore.guideIsFinished('welcome')) {
  //         $guide.create(guideBonusTabConfig, guideBonusTabOptions)
  //       }
  //     } else {
  //       $guide.create(guideBonusFabConfig, guideBonusFabOptions)
  //     }
  //   }
  // }
})
