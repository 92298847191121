import { defineNuxtPlugin } from '#app'
import scrollDirective from './scroll.directive'
import scrollLockDirective from './scroll-lock.directive'
import './style.css'

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.directive('scroll', scrollDirective())
  nuxtApp.vueApp.directive('scroll-lock', scrollLockDirective())

  nuxtApp.provide('scrollToHash', () => {
    const { hash } = useRoute()
    try {
      const element = document.querySelector(hash)
      if (element) {
        window.scrollTo({ top: element.getBoundingClientRect().top - 80 })
        element.classList.add('focus')
      }
    } catch (e) {}
  })

  nuxtApp.$router.options.scrollBehavior = route => {
    return { left: 0, top: 0 }
  }
})
