import type { Notifications } from 'lvl-io-sdk'
import { createItemsStore } from '~/utils/pinia/light-items-store'

export const useNotificationsStore = createItemsStore(
  'notifications',
  {
    getItemsRequests: async () => {
      const { $sdk } = useNuxtApp()
      const {
        notifications: { idKey, getItems, getMoreItems }
      } = await $sdk.module('notifications')

      return { idKey, getItems, getMoreItems }
    }
  },
  context => {
    const { $sdk } = useNuxtApp()

    let batchReadIds: Notifications.Notification['id'][] = []
    let readTimeout: number

    const unread = ref(0)

    const updateUnreadCount = (count = 0) => {
      unread.value = Math.max(0, count)
    }

    const fetchUnreadCount = async () => {
      const data = await $sdk
        .module('notifications')
        .then(({ getUnreadCount }) => getUnreadCount())
      updateUnreadCount(data)
    }

    const updateRead = (ids: Notifications.Notification['id'][]) => {
      context.items.value.forEach(item => {
        if (ids.includes(item.id)) {
          item.read_at = Math.floor(Date.now() / 1000)
        }
      })
    }

    const setRead = (id: Notifications.Notification['id']) => {
      clearTimeout(readTimeout)
      batchReadIds.push(id)

      readTimeout = setTimeout(async () => {
        await $sdk
          .module('notifications')
          .then(({ readNotifications }) =>
            readNotifications({ ids: batchReadIds })
          )

        updateRead(batchReadIds)
        updateUnreadCount(unread.value - batchReadIds.length)
        batchReadIds = []
      }, 1000) as unknown as number
    }

    const pushMessage = (message: Notifications.Notification) => {
      context.items.value.unshift(message)
    }

    const incUnread = () => {
      updateUnreadCount(unread.value + 1)
    }

    const readAll = async () => {
      await $sdk
        .module('notifications')
        .then(({ readAllNotifications }) => readAllNotifications())
      updateRead(context.items.value.map(item => item.id))
      unread.value = 0
    }

    return {
      unread,
      updateUnreadCount,
      fetchUnreadCount,
      updateRead,
      setRead,
      pushMessage,
      incUnread,
      readAll
    }
  }
)
