import { defineNuxtPlugin, navigateTo } from '#app'
import { useAccountStore } from '@/stores/account'
import { computed, watch } from 'vue'
import { useActivatorStore } from '~/stores/common/activator'
import { usePrizeModal } from '~/_plugins/activations/prize-modal'

export default defineNuxtPlugin(nuxtApp => {
  if (process.server) return

  const { $normalizePath, $dialog } = nuxtApp
  const accountStore = useAccountStore()
  const isAuthorized = computed(() => accountStore.isAuthorized)
  const activatorStore = useActivatorStore()

  watch(
    isAuthorized,
    async value => {
      if (value) {
        // Activation activator
        const activatedItem = await activatorStore.activation()

        if (activatedItem) {
          const redirectPath = $normalizePath('/tasks/')
          usePrizeModal().callModal(activatedItem)
          navigateTo(redirectPath)
        }
      }
    },
    {
      immediate: true
    }
  )
})
