import { defineNuxtRouteMiddleware } from '#imports'
import { useClansMeStore } from '~/stores/clans/me'
import { useClansSettingsStore } from '~/stores/clans/settings'

interface Options {
  alwaysRefreshMeData?: boolean
}

export const createClansMiddleware = (options: Options = {}) => {
  const middlewareOptions = Object.assign(
    {
      alwaysRefreshMeData: false
    },
    options
  )

  return async () => {
    const clansSettingsStore = useClansSettingsStore()
    const { $sdk } = useNuxtApp()

    if (!clansSettingsStore.data) {
      await clansSettingsStore.fetchData()
    }

    if (process.server) return

    const account = await $sdk
      .module('auth')
      .then(({ getAccount }) => getAccount())

    if (account) {
      const clansMeStore = useClansMeStore()
      if (
        middlewareOptions.alwaysRefreshMeData ||
        (!clansMeStore.data && !clansMeStore.fetched)
      ) {
        await clansMeStore.fetchData()
      }
    }
  }
}

export default defineNuxtRouteMiddleware(
  createClansMiddleware({
    alwaysRefreshMeData: false
  })
)
