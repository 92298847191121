export const usePrizeModal = () => {
  const { $dialog } = useNuxtApp()
  const callModal = item => {
    if (!item.silent) {
      $dialog({
        closeOnChangeRoute: false,
        content: defineAsyncComponent(
          () => import('~/components/common/reward/modal.vue')
        ),
        wrapper: null,
        contentProps: {
          resource: item.resource,
          amount: item.amount
        }
      })
    }
  }

  return { callModal }
}
