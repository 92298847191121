import { gtm, amplitude } from './core'
import type { Directive } from 'vue'
import type { AmplitudeArgs, GtmArgs, Binding } from './types'
import isEqual from 'lodash/isEqual'

export default function () {
  function createCallMethod(binding: Binding) {
    return () => {
      if (Array.isArray(binding.value)) {
        if (binding.modifiers.amplitude) {
          amplitude(...(binding.value as AmplitudeArgs))
        } else if (binding.modifiers.gtm) {
          gtm(...(binding.value as GtmArgs))
        }
      } else {
        if (binding.value.gtm?.length) {
          gtm(...(binding.value.gtm as GtmArgs))
        }
        if (binding.value.amplitude?.length) {
          amplitude(...(binding.value.amplitude as AmplitudeArgs))
        }
      }
    }
  }

  const updateMethod = (
    el: HTMLElement & {
      clickListener: ReturnType<typeof createCallMethod> | null
    },
    binding: Binding
  ) => {
    if (binding.modifiers.click && el.clickListener) {
      el.removeEventListener('click', el.clickListener, true)
      el.clickListener = null
    }
    if (binding.value === null || binding.value === undefined) {
      return
    }
    const callMethod = createCallMethod(binding)
    if (binding.modifiers.view) {
      callMethod()
    } else if (binding.modifiers.click) {
      el.clickListener = callMethod
      el.addEventListener('click', el.clickListener, true)
    }
  }

  return {
    mounted(el, binding) {
      updateMethod(el, binding)
    },
    updated(el, binding) {
      if (!isEqual(binding.value, binding.oldValue)) {
        updateMethod(el, binding)
      }
    },
    beforeUnmount(el, binding) {
      if (binding.modifiers.click) {
        el.removeEventListener('click', el.clickListener, true)
      }
    }
  } satisfies Directive<
    HTMLElement & { clickListener: ReturnType<typeof createCallMethod> },
    GtmArgs | AmplitudeArgs | { gtm?: GtmArgs; amplitude?: AmplitudeArgs }
  >
}
