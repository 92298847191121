import { defineNuxtPlugin } from '#app'
import { Dialog } from './dialog'
import UiSpinner from '@/components/ui/spinner/index.vue'
import type { Options } from './types'

export default defineNuxtPlugin(nuxtApp => {
  Dialog.setDefaultOptions({
    wrapper: defineAsyncComponent(
      () => import('@/components/common/dialog/content-wrapper.vue')
    ),
    loading: UiSpinner
  })

  return {
    provide: {
      dialog: (options?: Options) => new Dialog(nuxtApp.vueApp, options)
    }
  }
})
