import validate from "/project/node_modules/nuxt/dist/pages/runtime/validate.js";
import analytics_45global from "/project/middleware/analytics.global.ts";
import guide_45global from "/project/middleware/guide.global.ts";
import main_45global from "/project/middleware/main.global.ts";
import utm_45global from "/project/middleware/utm.global.ts";
import manifest_45route_45rule from "/project/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  analytics_45global,
  guide_45global,
  main_45global,
  utm_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  access: () => import("/project/middleware/access.ts"),
  auth: () => import("/project/middleware/auth.ts"),
  clans: () => import("/project/middleware/clans.ts"),
  error: () => import("/project/middleware/error.ts"),
  tester: () => import("/project/middleware/tester.ts")
}