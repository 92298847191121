import type { Clans } from 'lvl-io-sdk'
import { createSimpleStore } from '~/utils/pinia/light-simple-store'

type Clan = Pick<Clans.Clan, 'id' | 'tag' | 'name' | 'avatar'> &
  Pick<Clans.ClanExpanded, 'stat' | 'clanMember' | 'rating'>

const permissions = {
  owner: [
    'remove',
    'settings',
    'invites',
    'requests',
    'message',
    'change-role'
  ],
  admin: ['invites', 'requests', 'message'],
  member: []
}

export const useClansMeStore = createSimpleStore(
  'clans-me',
  {
    getRequests: async () => {
      const { $sdk } = useNuxtApp()
      const { getMyClan } = await $sdk.module('clans')

      return {
        get: (
          ...[requestQuery, pathOptions, requestOptions]: Parameters<
            typeof getMyClan<Clan>
          >
        ) =>
          getMyClan<Clan>(
            {
              expand: ['stat', 'clanMember', 'rating'],
              fields: ['id', 'tag', 'name', 'avatar'],
              ...(requestQuery ?? {})
            },
            pathOptions,
            requestOptions
          )
      }
    }
  },
  context => {
    const isMyClan = (tag: Clan['tag']) => context.data.value?.tag === tag

    const permission = (scope: string) => {
      const scopes = context.data.value
        ? permissions[context.data.value.clanMember.role.id] ?? []
        : []

      return scopes.includes(scope)
    }

    return { isMyClan, permission }
  }
)
