<template>
  <span :class="$style.t" :style="source.style" v-html="text"></span>
</template>

<script>
import replacelink from '~/utils/replacelink'

export default {
  name: 'DocumentContentText',
  props: {
    source: {
      type: Object,
      required: true
    }
  },
  computed: {
    text() {
      return replacelink(this.source.text, true)
    }
  }
}
</script>

<style lang="scss" module>
.t {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 1.8em;
  line-height: 130%;
}
</style>
