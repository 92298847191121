<template>
  <div
    :class="$style.container"
    :style="style"
    :data-events="options.pointerEvents"
  >
    <svg
      :width="rect.wwidth"
      :height="rect.wheight"
      :viewBox="`0 0 ${rect.wwidth} ${rect.wheight}`"
    >
      <path
        :style="{ pointerEvents: pointerEventsPath }"
        :d="`M 0,0 L ${rect.wwidth},0 L ${rect.wwidth},${rect.wheight} L 0, ${
          rect.wheight
        } L 0, ${rect.y + rect.height} L ${rect.x + rect.width},${
          rect.y + rect.height
        } L ${rect.x + rect.width}, ${rect.y} L ${rect.x}, ${rect.y} L ${
          rect.x
        }, ${rect.y + rect.height} L 0 ${rect.y + rect.height} Z`"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'AppGuideOverlay',
  props: {
    options: {
      type: Object,
      default: () => ({
        opacity: 0.5,
        pointerEvents: 'painted'
      })
    },
    rect: {
      type: Object,
      default: () => ({
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        r: 0
      })
    }
  },
  computed: {
    style() {
      return {
        opacity: this.options.opacity,
        pointerEvents: this.pointerEventsOverlay
      }
    },
    pointerEventsOverlay() {
      switch (this.options.pointerEvents) {
        case 'all':
          return 'all'
        default:
          return 'none'
      }
    },
    pointerEventsPath() {
      switch (this.options.pointerEvents) {
        // case 'painted' : return null
        default:
          return this.options.pointerEvents
      }
    }
  }
}
</script>

<style lang="scss" module>
.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
</style>
