<template>
  <div
    :class="classes"
    :style="style"
    v-analytics.view.amplitude="amplitude?.length && amplitude"
  >
    <div :class="$style.card">
      <div :class="$style.timeout" v-if="durationTime"></div>
      <span :class="$style.tail">
        <svg
          width="18"
          height="16"
          viewBox="0 0 18 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.26795 1C8.03775 -0.333333 9.96225 -0.333333 10.7321 1L17.6603 13C18.4301 14.3333 17.4678 16 15.9282 16H2.0718C0.532197 16 -0.430054 14.3333 0.339746 13L7.26795 1Z"
            fill="currentColor"
          />
        </svg>
      </span>
      <div :class="$style.header" v-if="title">
        <div :class="$style.title">
          <span :class="$style.text">{{ title }}</span>
        </div>
      </div>
      <div :class="$style.body" v-if="text">
        <span :class="$style.text" v-html="text"></span>
      </div>
      <div :class="$style.actions" v-if="buttons.length">
        <template v-if="buttons.length">
          <component
            :is="button.href ? 'a' : 'button'"
            v-bind="btnBindAttr(button)"
            v-for="(button, i) in buttons"
            :key="i"
            :class="[
              $style.button,
              $style[`theme-${button.theme}`],
              $style[`align-${button.align}`]
            ]"
            @click="onClick(button)"
            v-analytics.click.amplitude="
              button.amplitude?.length && button.amplitude
            "
          >
            <ui-icon name="support" v-if="button.icon" :class="$style.icon" />
            <span :class="$style.text">{{ button.text }}</span>
          </component>
          <span :class="$style.space"></span>
        </template>
        <div :class="$style.steps" v-if="stepsCount > 1 && showStepsCounter">
          <span
            :class="[$style.text, { [$style.last]: stepIndex === stepsCount }]"
            >{{ stepIndex }}/{{ stepsCount }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "goit": "Go it"
  },
  "ru": {
    "goit": "Ясненько"
  }
}
</i18n>

<script>
import UiIcon from '@/components/ui/icon/index.vue'
import AnalyticsDirective from '~/_plugins/analytics/analytics.directive'

export default {
  name: 'AppGuidePopover',
  components: {
    UiIcon
  },
  emits: ['next', 'skip'],
  data() {
    return {
      timeout: 0
    }
  },
  directives: {
    analytics: AnalyticsDirective()
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    amplitude: {
      type: Array,
      default: () => []
    },
    stepsCount: {
      type: Number,
      default: 0
    },
    showStepsCounter: {
      type: Boolean,
      default: true
    },
    duration: {
      type: Number,
      default: 0
    },
    stepIndex: {
      type: Number,
      default: 1
    },
    theme: {
      type: String,
      default: 'primary',
      validator: v => ['primary', 'dark', 'classic'].includes(v)
    },
    size: {
      type: String,
      default: 'small',
      validator: v => ['small', 'medium'].includes(v)
    },
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    buttons: {
      type: Array,
      default: () => [{ text: '', emit: 'next', href: '', align: 'left' }]
    },
    tail: {
      type: String,
      default: 'top'
    },
    className: {
      type: String,
      default: ''
    }
  },
  computed: {
    classes() {
      return [
        this.$style.container,
        this.className,
        this.$style[this.tail],
        this.$style['theme-' + this.theme],
        this.$style['size-' + this.size]
      ]
    },
    style() {
      return {
        visibility: this.visible ? 'visible' : 'hidden',
        '--dur': `${this.durationTime}ms`
      }
    },
    durationTime() {
      return this.duration
    }
  },
  methods: {
    onClick(button) {
      if (button.emit) {
        this.$emit(button.emit)
      }

      if (typeof button.cb === 'function') {
        button.cb(this.stepIndex)
      }
    },
    btnBindAttr(btn) {
      const attr = {}
      if (btn.href) {
        attr.href = btn.href
        attr.target = '_blank'
      }
      return attr
    }
  },
  mounted() {
    if (this.durationTime) {
      this.timeout = setTimeout(() => {
        this.$emit('next')
      }, this.durationTime)
    }
  },
  beforeUnmount() {
    clearTimeout(this.timeout)
  }
}
</script>

<style lang="scss" module>
.container {
  padding: 0 2em;
  @include down(md) {
  }
}
.card {
  position: relative;
  border-radius: var(--s-radius);
  padding: var(--s-padding);
  min-width: var(--s-minw);
  max-width: var(--s-maxw);
  background: var(--col-bg);
  box-shadow: var(--s-shadow, none);
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.title {
  margin-bottom: var(--s-title-mb);
  .text {
    font-family: var(--font-title, var(--primary-font));
    font-size: var(--s-title-fs);
    font-weight: var(--font-weight-title, 400);
    line-height: 120%;
    color: var(--col-title);
    font-style: normal;
  }
}
.steps {
  margin-left: 2em;

  @include down(sm) {
    margin-top: 1.2em;
    margin-left: 0;
  }

  .text {
    font-weight: 500;
    font-size: var(--s-counter);
    color: var(--col-counter);

    &.last {
      color: var(--col-counter-last, var(--col-counter));
    }
  }
}
.body {
  .text {
    font-weight: 400;
    font-size: var(--s-text-fs);
    line-height: 130%;
    color: var(--col-text);
    white-space: pre-line;
  }
  a {
    text-decoration-line: underline;
    color: #b4b4ff;
  }
}
.button {
  --b-bg: #252c3e;
  --b-bg-h: #252c3e;
  --b-color: #fff;
  --b-color-h: #fff;

  padding: var(--s-button-p);
  background: var(--b-bg);
  color: var(--b-color);
  border-radius: var(--s-button-r);
  border: 0 none;
  outline: none;
  text-align: center;
  min-width: var(--s-button-minw, 10em);
  display: flex;
  align-items: center;
  justify-content: center;
  transition-property: color, background;
  transition-duration: 0.2s;

  @include down(sm) {
    width: 100%;
  }

  &:hover {
    color: var(--b-color-h);
    background: var(--b-bg-h);
  }
  .icon {
    margin-right: 0.7em;
    width: 1.4em;
    height: 1.7em;
    flex-shrink: 0;
  }
  .text {
    font-weight: 500;
    font-size: var(--s-button-fs);
    line-height: 143%;
    letter-spacing: -0.02em;
  }
  &.theme-accent {
    --b-bg: #ffffff;
    --b-bg-h: #ffffff;
    --b-color: #000;
    --b-color-h: #000;
  }
  &.theme-dark {
    --b-bg: #b4b4ff;
    --b-bg-h: rgba(180, 180, 255, 0.94);
    --b-color: #41435c;
    --b-color-h: #41435c;
  }
  &.theme-important {
    --b-bg: var(--app-active-color);
    --b-bg-h: #00e1c8;
    --b-color: #0a0e29;
    --b-color-h: #0a0e29;
  }
  &.theme-secondary {
    --b-bg: #ebebeb;
    --b-bg-h: #dedede;
    --b-color: #a8a8a8;
    --b-color-h: #9b9b9b;
  }
  &.align-right {
    justify-self: flex-end;
  }
}
.button + .button {
  margin-left: 1.2em;
  @include down(sm) {
    margin-left: 0;
    margin-top: 1.2em;
  }
}
.timeout {
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1.6em;
  border-radius: inherit;
  &:after {
    display: block;
    width: 100%;
    background-color: var(--col-timeout);
    height: 2px;
    content: '';
    animation: timeout var(--dur) forwards linear;
    transform-origin: 0 0;
  }
}
.actions {
  margin-top: var(--s-action-mt);
  display: flex;
  align-items: center;
  white-space: nowrap;
  .space {
    flex: 1 1;
  }
  @include down(sm) {
    display: block;
  }
}

.theme-primary {
  --col-bg: #00a996;
  --col-title: #ffffff;
  --col-text: #eeeeee;
  --col-timeout: #fff;
  --col-counter: #b4b4ff;
}

.theme-classic {
  --col-bg: white;
  --col-title: var(--app-color-black);
  --col-text: #0c0c0c;
  --col-counter: #a8a8a8;
  --col-counter-last: var(--app-active-color);
  --font-title: var(--main-font);
  --font-weight-title: 700;
}

.size-small {
  --s-radius: 0.8em;
  --s-padding: 1.2em 1.6em 1.6em;
  --s-title-mb: 0.4em;
  --s-minw: 16em;
  --s-maxw: 20em;
  --s-title-fs: 1.6em;
  --s-text-fs: 1.4em;
  --s-counter: 1em;
  --s-action-mt: 1.6em;
  --s-button-p: 0.2em 0.8em;
  --s-button-r: 0.4em;
  --s-button-fs: 1.4em;
}

.size-medium {
  --s-radius: 0.8em;
  --s-padding: 3.2em;
  --s-title-mb: 1.6em;
  --s-minw: 32em;
  --s-maxw: 40em;
  --s-counter: 1.2em;
  --s-title-fs: 2em;
  --s-text-fs: 1.6em;
  --s-action-mt: 4.4em;
  --s-button-p: 0.8em 1.6em;
  --s-button-r: 0.7em;
  --s-button-fs: 1.6em;
}

.tail {
  position: absolute;
  color: var(--col-bg);
}
.top {
  margin-top: 1.2em;
  .tail {
    left: 50%;
    bottom: calc(100% - 0.7em);
    transform: translate(-50%, 0);
  }
}
.bottom {
  margin-top: -1.2em;
  .tail {
    top: calc(100% - 0.7em);
    left: 50%;
    transform: translate(-50%, 0);
    svg {
      transform: rotate(180deg);
    }
  }
}
.left {
  margin-left: 1.2em;
  .tail {
    top: 50%;
    right: calc(100% - 0.2em);
    transform: translate(25%, -50%) rotate(-90deg);
  }
}
.right {
  margin-right: 1.2em;
  .tail {
    top: 50%;
    left: calc(100% - 0.2em);
    transform: translate(-25%, -50%) rotate(90deg);
  }
}
.center {
  .tail {
    display: none;
  }
}

@keyframes timeout {
  0% {
    transform: scale(0, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
</style>
