import type { NuxtApp } from '#app'
import { normalizePath } from './utils'
import type { Options } from './types'

export default defineNuxtPlugin(nuxtApp => {
  type __NuxtApp = typeof nuxtApp & Pick<NuxtApp, '$i18n'>

  const { $i18n } = nuxtApp as __NuxtApp

  return {
    provide: {
      normalizePath: (path: string, options: Partial<Options> = {}) =>
        normalizePath(path, {
          locale: $i18n.locale.value,
          ...options
        })
    }
  }
})
