import image1 from '~/assets/images/docs/steam/1_en.jpg'
import image2 from '~/assets/images/docs/steam/2_en.jpg'

export default {
  sections: [
    {
      type: 'section',
      title: 'How to complete tasks in CS and Dota 2',
      fullWidth: true,
      sections: [
        {
          type: 'paragraph',
          text: 'Dear participants!'
        },
        {
          type: 'paragraph',
          text: 'Please make your profile public in order for our system to see and track your progress in CS and Dota2 missions.'
        },
        {
          type: 'list',
          items: [
            {
              type: 'text',
              text: 'Make your Steam profile public. Open steamcommunity.com in your browser or your PC Steam application;'
            },
            {
              type: 'text',
              text: 'Switch to the «Profile» tab.'
            },
            {
              type: 'text',
              text: 'Go to: «Edit profile» – «Privacy».'
            },
            {
              type: 'text',
              text: 'In the stores «My profile» and «Game details» select the «Public» setting. It should look like this:'
            }
          ]
        },
        {
          type: 'image',
          src: image1
        }
      ]
    },
    {
      type: 'section',
      title: 'This is how to make your Dota 2 profile public:',
      fullWidth: true,
      sections: [
        {
          type: 'list',
          items: [
            {
              type: 'text',
              text: 'Open your match history inside the game: launch Dota2, go to Settings, then Community;'
            },
            {
              type: 'text',
              text: 'Check the “Expose public match data” box as shown in the screenshot below:'
            }
          ]
        },
        {
          type: 'image',
          src: image2
        }
      ]
    }
  ]
}
