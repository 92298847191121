import { useRuntimeConfig } from 'nuxt/app'
import hash from 'object-hash'
import { TTLCache } from './ttl-cache'
import type {
  RequestUrl,
  RequestMethod,
  RequestOptions,
  RequestInterceptor,
  ResponseInterceptor,
  AuthFn,
  ApiOptions,
  PublicMethodInterface,
  ApiError
} from './api-types'

const cache = new TTLCache({ debug: false })
const onRequestInterceptors = new Set<RequestInterceptor>()
const onResponseInterceptors = new Set<ResponseInterceptor>()

let authorization: AuthFn = _data => {}
let publicKey = ''
let origin = ''

export const setPublicKey = (value: string) => {
  publicKey = value
}

export const setOrigin = (value: string) => {
  origin = value
}

export const addRequestInterceptor = (fn: RequestInterceptor) => {
  onRequestInterceptors.add(fn)
  return () => removeRequestInterceptor(fn)
}
export const addResponseInterceptor = (fn: ResponseInterceptor) => {
  onResponseInterceptors.add(fn)
  return () => removeResponseInterceptor(fn)
}
export const removeRequestInterceptor = (fn: RequestInterceptor) =>
  onRequestInterceptors.delete(fn)
export const removeResponseInterceptor = (fn: ResponseInterceptor) =>
  onResponseInterceptors.delete(fn)

export const isApiError = <T>(err: Error): err is ApiError<T> =>
  'request' in err || 'response' in err

export const useApiClient = () => {
  const { hooks, $i18n } = useNuxtApp()
  const $config = useRuntimeConfig()
  const baseURL = process.server ? $config.apiBase : $config.public.apiBase
  const $client = $fetch.create({ baseURL })

  async function request<T>(
    url: RequestUrl,
    method: RequestMethod,
    requestOptions: RequestOptions = {},
    apiOptions: ApiOptions = {}
  ): Promise<T> {
    const _apiOptions = Object.assign(
      { useAuthorization: true, cache: false },
      apiOptions
    )
    const language = $i18n.locale.value
    const id = hash({
      url,
      method,
      language,
      query: requestOptions.query
    })

    if (_apiOptions.cache && cache.has(id)) {
      return cache.get(id)
    }

    return $client<T>(
      url,
      Object.assign({}, requestOptions, {
        method,
        headers: Object.assign(
          {
            'X-Public-Key': publicKey,
            Referer: origin
          },
          requestOptions?.headers
        ),
        async onRequest({ request, options }) {
          ;(options.headers as Record<string, string>)['accept-language'] =
            language

          if (authorization && _apiOptions.useAuthorization) {
            await authorization({ request, options })
          }

          if (typeof requestOptions.onRequest === 'function') {
            await requestOptions.onRequest({ request, options })
          }

          onRequestInterceptors.forEach(fn => {
            fn({ id, request, options })
          })
        },
        async onResponse({ error, options, request, response }) {
          if (
            response.status >= 200 &&
            response.status < 399 &&
            _apiOptions.cache
          ) {
            cache.set(id, response._data, _apiOptions.cache)
          }

          if (typeof requestOptions.onResponse === 'function') {
            await requestOptions.onResponse({
              error,
              options,
              request,
              response
            })
          }

          onResponseInterceptors.forEach(fn => {
            fn({ id, error, options, request, response })
          })
        },
        async onResponseError(error) {
          // @ts-ignore
          hooks.callHook('api-client:error', error, id)
          return Promise.reject(error)
        }
      } satisfies RequestOptions)
    )
  }

  return {
    ...({
      get: (url, options?, apiOptions?) =>
        request(url, 'GET', options, apiOptions),

      post: (url, options?, apiOptions?) =>
        request(url, 'POST', options, apiOptions),

      put: (url, options?, apiOptions?) =>
        request(url, 'PUT', options, apiOptions),

      delete: (url, options?, apiOptions?) =>
        request(url, 'DELETE', options, apiOptions)
    } satisfies PublicMethodInterface),

    setAuthorization: (fn: AuthFn = _data => {}) => {
      authorization = fn
    }
  }
}
