import { disablePageScroll, enablePageScroll } from 'scroll-lock'

export default function () {
  return {
    mounted(el, binding, vnode) {
      disablePageScroll(el)
    },
    unmounted(el, binding, vnode) {
      enablePageScroll(el)
    }
  }
}
