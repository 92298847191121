<template>
  <div :class="$style.list">
    <transition-group :name="$style.listComplete" tag="div">
      <snacks-item
        v-for="snack in snacks"
        :key="snack.id"
        :source="snack"
        @close="removeNotification"
      />
    </transition-group>
  </div>
</template>

<script lang="ts" setup>
import SnacksItem from './snack.vue'
import { TYPES } from '../consts'
import type { Snack, PublicInterface } from '../types'

defineOptions({
  name: 'AppSnacks'
})

const snacks = ref<Snack[]>([])

const removeNotification = (idToRemove: Snack['id']) => {
  snacks.value = snacks.value.filter(({ id }) => id !== idToRemove)
}

type OpenOverload = {
  (
    type: Snack['type'],
    title: Snack['title'],
    message?: Snack['message'],
    _options?: Snack['options']
  ): void

  (
    type: Snack['type'],
    message: Snack['message'],
    _options?: Snack['options']
  ): void
}

const open: OpenOverload = (type, title, message = '', _options = {}) => {
  if (typeof message === 'object') {
    _options = message
    message = title
    title = ''
  }

  const id = (Math.random() + 1).toString(36).substring(7)
  const options = Object.assign(
    {
      duration: 5000
    },
    _options
  )

  snacks.value.push({ id, type, title, message, options })

  if (Number.isFinite(options.duration)) {
    setTimeout(() => removeNotification(id), options.duration)
  }
}

const publicInterface = TYPES.reduce((acc: PublicInterface, type) => {
  acc[type] = (title, message: any, options?: any) =>
    open(type, title, message, options)

  return acc
}, {} as any)

defineExpose(publicInterface)
</script>

<style lang="scss" module>
.list {
  @include appSize;
  position: fixed;
  z-index: 1000;
  text-align: center;
  width: 34em;
  bottom: 0;
  right: 4em;
  pointer-events: none;
  @include down(md) {
    width: 90%;
    top: 0;
    margin-top: 2em;
    left: 50%;
    transform: translate(-50%, 0);
  }
  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.listComplete {
  &:global(-leave-active) {
    opacity: 0;
    transform: translateY(3em);
    @include down(md) {
      transform: translateY(-3em);
    }
  }
  &:global(-enter-from) {
    opacity: 0;
    transform: translateY(3em);
    @include down(md) {
      transform: translateY(-3em);
    }
  }
  &:global(-leave-to) {
    opacity: 0;
    transform: translateY(3em);
    @include down(md) {
      transform: translateY(-3em);
    }
  }
}
</style>
