import { defineStore } from 'pinia'
import type { Ref } from 'vue'

/*
  We do not annotate with types from the SDK, since the whole job of the store is to call a method and save the result.
  Basically, getItems and getMoreItems are any async functions, and getItemsRequests is just an async function that returns them
*/
type ItemsRequest = (...args: any[]) => Promise<any>
type GetItemsRequests = () => Promise<{
  idKey: string
  getItems: ItemsRequest
  getMoreItems?: ItemsRequest
}>

interface Options<GetItemsReqs extends GetItemsRequests = GetItemsRequests> {
  getItemsRequests: GetItemsReqs
}

type Extending = Record<string, any> // we can extend a store in any way we want

const storeSetup = <GetItemsReqs extends GetItemsRequests>({
  getItemsRequests
}: Options<GetItemsReqs>) => {
  type ReceivedItemsRequests = Awaited<ReturnType<GetItemsReqs>>

  type K = ReceivedItemsRequests['idKey']

  type ReceivedGetItems = ReceivedItemsRequests['getItems']
  type ReceivedGetItemsParams = Parameters<ReceivedGetItems>
  type ReceivedGetItemsReturn = Awaited<ReturnType<ReceivedGetItems>>

  type T = ReceivedGetItemsReturn extends any[]
    ? ReceivedGetItemsReturn[number]
    : ReceivedGetItemsReturn['items'][number]

  type Meta = ReceivedGetItemsReturn extends any[]
    ? null
    : ReceivedGetItemsReturn['meta']

  type Query = ReceivedGetItemsReturn extends any[]
    ? null
    : ReceivedGetItemsReturn['query']

  const items = ref([]) as Ref<T[]>
  const meta = ref(null) as Ref<Meta | null>
  const url = ref('')
  const query = ref(null) as Ref<Query | null>
  const isLastPage = ref(true)

  const reset = () => {
    items.value = []
    meta.value = null
    query.value = null
    isLastPage.value = true
  }

  const setResult = (result: any) => {
    if (Array.isArray(result)) {
      items.value = result
    } else {
      items.value = result.items
      meta.value = result.meta
      url.value = result.url
      query.value = result.query
      isLastPage.value = result.isLastPage
    }
  }

  const getData = () => ({
    items: items.value,
    meta: meta.value,
    url: url.value,
    query: query.value,
    isLastPage: isLastPage.value
  })

  type RequestOptions = ReceivedGetItemsParams[2] & {
    resetBeforeFetch?: boolean
  }

  const fetchItems = async (
    requestQuery?: ReceivedGetItemsParams[0],
    pathOptions?: ReceivedGetItemsParams[1],
    requestOptions?: RequestOptions
  ) => {
    if (requestOptions?.resetBeforeFetch ?? true) {
      reset()
    }

    const result = await getItemsRequests().then(({ getItems }) =>
      getItems(requestQuery, pathOptions, requestOptions)
    )

    setResult(result)

    return items.value
  }

  const fetchMoreItems = async () => {
    const data = getData()

    const result = await getItemsRequests().then(
      ({ getMoreItems }) =>
        getMoreItems?.(data) ??
        Promise.reject(new Error('Method getMoreItems is not implemented'))
    )

    if (!result) {
      return
    }

    setResult(result)

    return items.value
  }

  const updateItem = async (id: T[K], updates: Partial<T>) => {
    const { idKey } = await getItemsRequests()

    const targetId = id.toString()
    const index = items.value.findIndex(
      item => item[idKey].toString() === targetId
    )

    if (index > -1) {
      items.value[index] = Object.assign(items.value[index], updates)
    }
  }

  const addLeft = (data: T) => {
    items.value.unshift(data)
  }

  const addRight = (data: T) => {
    items.value.push(data)
  }

  const removeItem = async (id: T[K]) => {
    const { idKey } = await getItemsRequests()

    const targetId = id.toString()
    const index = items.value.findIndex(
      item => item[idKey].toString() === targetId
    )

    if (index > -1) {
      items.value.splice(index, 1)
    }

    if (meta.value?.totalCount) {
      meta.value.totalCount = Math.max(meta.value.totalCount - 1, 0)
    }
  }

  return {
    items,
    meta,
    url,
    query,
    isLastPage,
    reset,
    fetchItems,
    fetchMoreItems,
    updateItem,
    addLeft,
    addRight,
    removeItem
  }
}

export const createItemsStore = <
  GetItemsReqs extends GetItemsRequests,
  E extends Extending = {}
>(
  key: string,
  options: Options<GetItemsReqs>,
  setup?: (context: ReturnType<typeof storeSetup<GetItemsReqs>>) => E
) =>
  defineStore(key, () => {
    const context = storeSetup(options)
    const extending = Object.assign({}, setup?.(context))

    return { ...context, ...extending }
  })
