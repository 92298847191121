import { Time } from './plugin'
import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin(nuxtApp => {
  function instance(timestamp) {
    const locale = nuxtApp.$i18n ? nuxtApp.$i18n.locale.value : 'en'
    return new Time({ timestamp, locale })
  }

  instance.setTimestamp = function (timestamp) {
    Time.setOffset(Date.now() - timestamp)
  }

  nuxtApp.provide('time', instance)
})
