<template>
  <p :class="$style.container">
    <content-text :source="source" />
  </p>
</template>

<script>
import ContentText from './text'
export default {
  name: 'DocumentContentParagraph',
  components: {
    ContentText
  },
  props: {
    source: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" module>
.container {
}
.container + .container {
  margin-top: 3em;
}
</style>
