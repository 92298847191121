import { defineNuxtPlugin } from '#app'
import { createApp } from 'vue'
import AppGuideComponent from './components/guide.vue'

export default defineNuxtPlugin(() => {
  const app = createApp(AppGuideComponent)
  const element = document.createElement('div')
  document.body.appendChild(element)
  const instance = app.mount(element) as any

  return {
    provide: {
      guide: {
        create: instance.create,
        open: instance.open,
        openId: instance.openId,
        stop: instance.stop,
        next: instance.next,
        get active() {
          return instance.isActive()
        }
      }
    }
  }
})
