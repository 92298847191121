import { defineStore } from 'pinia'

export const PRIVILEGE_GROUP_KEY = 'users'
export const PRIVILEGE_KEY_VIP = 'vip'

export const useAccountStore = defineStore('account', {
  state: () => ({
    account: null,
    auths: null,
    stat: null,
    ranks: null,
    privilegeKeys: null
  }),
  actions: {
    async fetchData(expand, fields = []) {
      const { $sdk } = useNuxtApp()
      try {
        return $sdk.module('auth').then(({ getIdentity }) =>
          getIdentity({
            expand,
            fields
          })
        )
      } catch (e) {
        return null
      }
    },
    async fetchAccount() {
      this.account = await this.fetchData(
        ['coins', 'profile', 'styles', 'scopes'],
        [
          'id',
          'email',
          'uid',
          'username',
          'avatar',
          'coins',
          'profile.agree',
          'steam_id'
        ]
      )
      return this.account
    },
    async fetchAccountAuths() {
      this.auths = await this.fetchData(['auths'], ['auths'])
      return this.auths
    },
    async fetchAccountStat() {
      this.stat = await this.fetchData(
        ['fillingProgress', 'stat', 'ranks'],
        ['fillingProgress', 'stat', 'ranks']
      )
      return this.stat
    },
    async fetchAccountRanks() {
      const { $sdk } = useNuxtApp()
      await $sdk.module('auth').then(({ getAccount }) => getAccount())
      if (!this.isAuthorized) {
        return
      }
      const { ranks } = await this.fetchData(['ranks'], ['ranks'])
      this.ranks = ranks
      return this.ranks
    },
    async fetchPrivilegeKeys() {
      const { $sdk } = useNuxtApp()
      this.privilegeKeys = await $sdk
        .module('privileges')
        .then(({ getPrivilegeKeys }) =>
          getPrivilegeKeys({}, { groupKey: PRIVILEGE_GROUP_KEY })
        )
      return this.privilegeKeys
    },
    setBalance(code, balance) {
      if (this.account) {
        const coin = this.account.coins.find(coin => coin.code === code)
        if (coin) {
          coin.balance = balance
        } else {
          this.account.coins.push({ code, balance })
        }
      }
    },
    updateAccount(update = {}) {
      if (this.account) {
        this.account = Object.assign(this.account, update)
      }
    }
  },
  getters: {
    isAuthorized: state => !!state.account,
    isConnected(state) {
      return source => {
        if (state.auths) {
          const auths = state.auths.auths || []
          return !!auths.find(item => item.source === source)
        }
        return false
      }
    },
    balance(state) {
      return coinType => {
        if (state.account) {
          const coin = state.account.coins.find(coin => coin.code === coinType)
          return coin?.balance || 0
        }
        return 0
      }
    },
    findSourceId(state) {
      return source =>
        state.auths?.auths?.find(item => item.source === source)?.source_id
    },
    isRole(state) {
      return role => state.account?.role === role
    },
    hasScope(state) {
      return scope => state.account?.scopes.includes(scope)
    }
  }
})
