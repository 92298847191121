// const actions = [
//   'coinTransaction',
//   'taskMemberProgress',
//   'taskMemberCompleted',
//   'csgoTokenStatus',
//   'notification',
//   'subscribeNotifications',
//   'chat:message'
// ]

function generateId() {
  return (Math.random() + 1).toString(36).substring(7)
}

function kebabToCC(str) {
  return str.replace(/-./g, match => match[1].toUpperCase())
}

export default function (sdk) {
  const instances = new Map()

  return {
    async mounted(el, binding) {
      const host = await getHost(binding)
      const id = el.dataset.wsListenerId || generateId()
      const actions = []

      if (typeof binding.value === 'object') {
        Object.keys(binding.value).forEach(actionKey => {
          actions.push({
            action: kebabToCC(actionKey),
            callback: binding.value[actionKey]
          })
        })
      } else {
        actions.push({
          action: kebabToCC(Object.keys(binding.modifiers)[0] || ''),
          callback: binding.value
        })
      }

      if (!actions.length) {
        throw new Error(`WS directive: action name required`)
      }

      if (!instances.has(id)) {
        instances.set(id, actions)
        el.dataset.wsListenerId = id
      }

      actions.forEach(item => {
        host?.addEventListener(item.action, item.callback)
      })
    },

    async unmounted(el, binding) {
      const host = await getHost(binding)
      const id = el.dataset.wsListenerId
      const actions = instances.get(id)

      if (actions) {
        actions.forEach(item => {
          host?.removeEventListener(item.action, item.callback)
        })
        instances.delete(id)
      }
    }
  }

  async function getHost({ arg }) {
    const [moduleName, fnArg] = (arg ?? 'common').split(':')
    const { getWSInstance } = await sdk.module(moduleName)

    return getWSInstance(fnArg)
  }
}
