import { defineNuxtPlugin } from '#app'
import { Media } from './media'

export default defineNuxtPlugin(nuxtApp => {
  const media = new Media({
    breakpoints: {
      xs: 360,
      sm: 568,
      md: 784,
      lg: 960,
      hd: 1280,
      fhd: 1920
    } as const
  })

  // https://nuxt.com/docs/api/advanced/hooks
  nuxtApp.hook('app:suspense:resolve', () => {
    media.enable()
  })

  return {
    provide: {
      matchMedia: media
    }
  }
})
