<template>
  <a :class="$style.t" :href="source.href" :target="target" :rel="rel">{{
    source.text
  }}</a>
</template>

<script>
export default {
  name: 'DocumentContentLink',
  props: {
    source: {
      type: Object,
      required: true
    }
  },
  computed: {
    target() {
      return '_blank'
    },
    rel() {
      return 'noopener'
    }
  }
}
</script>

<style lang="scss" module>
.t {
  font-size: 1.8em;
  line-height: 130%;
  color: #8aeebe;
  margin-left: 0.5em;
  &:hover {
    text-decoration: underline;
  }
}
</style>
