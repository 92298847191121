import type { NuxtApp } from '#app'
import appConfig from '~/app.config'
import { useAccountStore } from '~/stores/account'
import authRequired from './auth-required.directive'

export default defineNuxtPlugin(nuxtApp => {
  type __NuxtApp = typeof nuxtApp & Pick<NuxtApp, '$router' | '$sdk'>

  nuxtApp.vueApp.directive('auth-required', authRequired())

  const { $router, $sdk } = nuxtApp as __NuxtApp

  const accountStore = useAccountStore()

  if (process.client) {
    $router.beforeEach(async (to, _from, next) => {
      if (appConfig.auth.callbackPath === to.path) {
        const result = await $sdk
          .module('auth')
          .then(({ loginSocialComplete }) => loginSocialComplete())

        if (result.success && result.redirect) {
          location.href = result.redirect
        }
      }

      next()
    })
  }

  return {
    provide: {
      auth: {
        // TODO: write types for the store
        get account(): any {
          return accountStore.account
        }
      }
    }
  }
})
