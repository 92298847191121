export const TYPES = [
  'info',
  'success',
  'warning',
  'error',
  'errorHigh',
  'increase',
  'decrease'
] as const
export type Type = (typeof TYPES)[number]
