import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'

export const useGuideStore = defineStore('guide', {
  state: () => ({
    initialized: false,
    name: '',
    state: '',
    data: {},
    localCompleted: useStorage('dphub/guide/local/completed', [])
  }),
  getters: {
    guideIsFinished(state) {
      return name => state.localCompleted.includes(name)
    }
  },
  actions: {
    resetLocalState() {
      this.localCompleted = []
    },
    setLocalStateAsFinished(stateName) {
      if (!this.localCompleted.includes(stateName)) {
        this.localCompleted.push(stateName)
      }
    }
  },
  hydrate(state, initialState) {
    // in this case we can completely ignore the initial state since we
    // want to read the value from the browser
    state.localCompleted = useStorage('dphub/guide/local/completed', [])
  }
})
