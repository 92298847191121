<template>
  <div :class="[$style.alert, classes]" :style="style">
    <div :class="$style.icon" v-if="icon">
      <component :is="icon" />
    </div>

    <div :class="$style.body">
      <div :class="$style.title" v-if="source.title">
        <span :class="$style.text">{{ source.title }}</span>
      </div>
      <span :class="$style.message">
        <span :class="$style.text">{{ source.message }}</span>
      </span>
    </div>

    <button :class="$style.close" @click="$emit('close', source.id)">
      <icon-close />
    </button>

    <div :class="$style.timeout" v-if="source.options.duration"></div>
  </div>
</template>

<script lang="ts" setup>
import IconClose from './icons/close.vue'
import IconWarning from './icons/warning.vue'
import IconBell from './icons/bell.vue'
import IconCheck from './icons/check.vue'
import type { Snack } from '../types'

defineOptions({
  name: 'SnacksItem'
})

const props = defineProps<{
  source: Snack
}>()

defineEmits<{
  close: [sourceId: Snack['id']]
}>()

const style = computed(() => ({
  ...(Number.isFinite(props.source.options.duration)
    ? { '--dur': `${props.source.options.duration}ms` }
    : {})
}))

const cssModule = useCssModule()

const classes = computed(() => cssModule[props.source.type])

const icon = computed(() => {
  switch (props.source.type) {
    case 'info':
      return IconBell

    case 'success':
      return IconCheck

    case 'warning':
    case 'errorHigh':
    case 'error':
      return IconWarning

    default:
      return null
  }
})
</script>

<style lang="scss" module>
.alert {
  transition:
    transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 1),
    opacity 0.5s cubic-bezier(0.785, 0.135, 0.15, 1);
  pointer-events: all;
  color: #fff;
  margin-bottom: 1.5em;
  border-radius: 0.6em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-wrap: break-word;
  position: relative;
  flex-wrap: nowrap;
  opacity: 1;
  text-align: left;
  padding: 1.6em 1.6em 2em;
  backdrop-filter: blur(6px);
  background-color: #acb0d5;
  overflow: hidden;
  width: 100%;
}
.icon {
  position: relative;
  padding-top: 0.3em;
  color: #fff;
  align-self: flex-start;
  margin-right: 1.6em;
  svg {
    width: 2.4em;
    height: auto;
  }
}
.message {
  .text {
    font-weight: 400;
    font-size: 1.6em;
    line-height: 138%;
  }
}
.title {
  .text {
    font-weight: 500;
    font-size: 1.8em;
    line-height: 144%;
  }
}
.close {
  position: absolute;
  top: 0.8em;
  right: 0.8em;
  color: #fff;
  cursor: pointer;
  background: none;
  border-radius: 0;
  outline: none;
  svg {
    width: 1.6em;
    height: 1.6em;
  }
  &:hover {
    opacity: 0.5;
  }
}

.error {
  background: #41435c;
  color: #ff898a;
  .icon {
    color: #ff898a;
  }
  .close {
    color: rgba(255, 255, 255, 0.7);
  }
}
.errorHigh {
  background: #c2104c;
  .icon {
    color: #fff;
  }
  .close {
    color: rgba(255, 255, 255, 0.7);
  }
}
.increase,
.success {
  background-color: #00c7b1;
  color: #0f1622;
  .icon {
    color: #0f1622;
  }
  .close {
    color: #0f1622;
  }
}

.warning {
  background-color: #ffc144;
  color: #2d2d33;
  .icon {
    color: #2d2d33;
  }
  .close {
    color: rgba(0, 0, 0, 0.5);
  }
}
.decrease,
.info {
  background-color: #41435c;
  color: #fff;
  .icon {
    color: #fff;
  }
  .close {
    color: rgba(255, 255, 255, 0.7);
  }
}
.decrease,
.increase {
  max-width: 26em;
  .text {
    font-family: var(--primary-font), sans-serif;
    font-style: var(--primary-font-style);
    font-weight: 700;
    font-size: 2em;
  }
}
.timeout {
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2em;
  border-radius: inherit;
  &:after {
    display: block;
    width: 100%;
    background-color: #fff;
    height: 2px;
    content: '';
    animation: timeout var(--dur) forwards linear;
    transform-origin: 0 0;
  }
}
@keyframes timeout {
  0% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(0, 1);
  }
}
</style>
