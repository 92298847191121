<template>
  <ul :class="$style.container">
    <li :class="$style.item" v-for="(item, i) in source.items" :key="i">
      <document-content :source="item" />
    </li>
  </ul>
</template>

<script>
export default {
  name: 'DocumentContentList',
  components: {},
  props: {
    source: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" module>
.container {
  margin-top: 1em;
  margin-bottom: 4em;
  padding-left: 2em;
}
.item + .item {
  margin-top: 3em;
}
</style>
