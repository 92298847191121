import { defineNuxtPlugin, type NuxtApp } from '#app'
import directiveActions from './directive-actions'
import directiveChannel from './directive-channel'

export default defineNuxtPlugin(nuxtApp => {
  type __NuxtApp = typeof nuxtApp & Pick<NuxtApp, '$sdk'>

  const { $sdk } = nuxtApp as __NuxtApp

  nuxtApp.vueApp.directive('ws-action', directiveActions($sdk))
  nuxtApp.vueApp.directive('ws-channel', directiveChannel($sdk))
})
