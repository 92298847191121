import image1 from '~/assets/images/docs/steam/1_ru.jpg'
import image2 from '~/assets/images/docs/steam/2_ru.jpg'

export default {
  sections: [
    {
      type: 'section',
      title: 'Выполнение заданий, связанных с CS и Dota 2',
      fullWidth: true,
      sections: [
        {
          type: 'paragraph',
          text: 'Уважаемые участники!'
        },
        {
          type: 'paragraph',
          text: 'Чтобы наша система могла видеть ваш прогресс выполнения заданий в CS и Dota2, пожалуйста, откройте свой профиль.'
        },
        {
          type: 'list',
          items: [
            {
              type: 'text',
              text: 'Сделайте профиль Steam публичным. Откройте через браузер сайт steamcommunity.com или зайдите в приложении Steam на компьютере;'
            },
            {
              type: 'text',
              text: 'Перейдите в раздел «Профиль».'
            },
            {
              type: 'text',
              text: 'Пройдите путь: «Редактировать профиль» – «Приватность».'
            },
            {
              type: 'text',
              text: 'В подпунктах «Мой профиль» и «Доступ к игровой информации» выберите пункт «Открытый». Вот так:'
            }
          ]
        },
        {
          type: 'image',
          src: image1
        }
      ]
    },
    {
      type: 'section',
      title: 'Как открыть профиль для Dota 2:',
      fullWidth: true,
      sections: [
        {
          type: 'list',
          items: [
            {
              type: 'text',
              text: 'Откройте историю матчей в самой игре: запустите Dota 2, перейдите в Настройки – «Сообщество»;'
            },
            {
              type: 'text',
              text: 'Поставьте галочку около подпункта «Общедоступная история матчей», как на скриншоте ниже:'
            }
          ]
        },
        {
          type: 'image',
          src: image2
        }
      ]
    }
  ]
}
