import type { Common } from 'lvl-io-sdk'
import { createSimpleStore } from '~/utils/pinia/light-simple-store'

type Coin = Common.Settings['coins'][number]

interface ExtraData {
  timeSync?: boolean
  webview?: boolean
}

export const useSettingsStore = createSimpleStore(
  'settings',
  {
    getRequests: async () => {
      const { $sdk } = useNuxtApp()
      const { getSettings } = await $sdk.module('common')

      return {
        get: getSettings
      }
    }
  },
  context => {
    const extraData = ref<ExtraData>({})

    const getCoinCode = (id: Coin['id']) =>
      context.data.value?.coins.find(coin => coin.id === id)?.code

    const getCoinIdByCode = (code: Coin['code']) =>
      context.data.value?.coins.find(coin => coin.code === code)?.id

    const fetchTime = async () => {
      const { $sdk } = useNuxtApp()

      const { time } = await $sdk
        .module('common')
        .then(({ getState }) => getState())

      extraData.value.timeSync = true

      if (context.data.value) {
        context.data.value.time = time
      }

      return time
    }

    const setWebview = (isWebview: boolean) => {
      extraData.value.webview = isWebview
    }

    return { extraData, getCoinCode, getCoinIdByCode, fetchTime, setWebview }
  }
)
