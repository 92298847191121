const SOCIALS = {
  fb: (url, title, description, image) =>
    `https://www.facebook.com/sharer/sharer.php?u=${url}`,
  vk: (url, title, description, image) =>
    `https://vk.com/share.php?url=${url}&title=${title}%20${description}&image=${image}`,
  tw: (url, title, description, image) =>
    `https://twitter.com/intent/tweet?url=${url}&text=${title}%20${description}`,
  tg: (url, title, description, image) =>
    `https://t.me/share/url?url=${url}&text=${title}%20${description}`,
  tgScheme: (url, title, description, image) =>
    `tg://share?url=${url}&text=${title}%20${description}`,
  ok: (url, title, description, image) =>
    `https://connect.ok.ru/offer?url=${url}&title=${title}&imageUrl=${image}`,
  reddit: (url, title, description, image) =>
    `https://www.reddit.com/submit?url=${url}&title=${title}%20${description}&video_poster_url=${image}`
}

export default class Share {
  open(social, params = {}) {
    const data = Share.getData(params)
    const createLink = SOCIALS[social]
    if (createLink) {
      const link = createLink(
        data.url,
        data.title,
        data.description,
        data.image
      )
      Share.popup(link)
    }
  }

  static getData(params) {
    const metaDescription = document.querySelector('meta[name="description"]')
    const metaImage = document.querySelector('meta[name="og:image"]')
    const defaultDescription = metaDescription ? metaDescription.content : ''
    const defaultImage = metaImage ? metaImage.content : ''

    const url = encodeURIComponent(params.url || location.href)
    const title = encodeURIComponent(
      typeof params.title === 'string' ? params.title : document.title
    )
    const description = encodeURIComponent(
      typeof params.description === 'string'
        ? params.description
        : defaultDescription
    )
    const image = encodeURIComponent(
      typeof params.image === 'string' ? params.image : defaultImage
    )

    return { url, title, description, image }
  }

  static popup(link) {
    const windowParams =
      'width=630&height=340&personalbar=0&toolbar=no&scrollbars=no&resizable=yes&location=no'
    const left = Math.floor(window.screen.width / 2 - 630 / 2)
    const right = Math.floor(window.screen.height / 5)
    if (link) {
      window.open(
        link,
        'share_dialog',
        `${windowParams.replace(/&/g, ',')},left=${left},top=${right}`
      )
    }
  }
}
