interface GeneralOptions {
  debug?: boolean
}

interface CacheRecord<T> {
  value: T
  expired: number
}

type SetOptions = boolean | { ttl?: number }

const defaultSetOptions = {
  ttl: 0 // ms
} satisfies SetOptions

export class TTLCache<T = any> {
  debug?: boolean
  private records = new Map<string, CacheRecord<T>>()

  constructor(options: GeneralOptions = {}) {
    this.debug = options.debug
  }

  set(key: string, value: T, options: SetOptions = {}) {
    const _options = Object.assign({}, defaultSetOptions, options)
    const expired = _options.ttl > 0 ? _options.ttl + Date.now() : 0
    this.records.set(key, { value, expired })
    this.log('set', key, value, options)
  }

  get(key: string) {
    const data = this.records.get(key)
    const hasKey = data && isNotExpired(data)
    this.log('get', key, hasKey)
    return hasKey ? data.value : null
  }

  has(key: string) {
    const data = this.records.get(key)
    return data && isNotExpired(data)
  }

  delete(key: string) {
    this.records.delete(key)
    this.log('delete', key)
  }

  async getOrSet(key: string, fn?: () => Promise<T>, options?: SetOptions) {
    let value = this.get(key)
    if (!value && fn) {
      value = await fn()
      this.set(key, value, options)
    }
    return value
  }

  get size() {
    return this.records.size
  }

  clearExpired() {
    this.records.forEach((data, key) => {
      if (!isNotExpired(data)) {
        this.delete(key)
      }
    })
  }

  private log(...args: any[]) {
    if (this.debug) {
      // eslint-disable-next-line no-console
      console.log('TTLCache', ...args)
    }
  }
}

function isNotExpired<T>(data: CacheRecord<T>, now = Date.now()) {
  return Boolean(data.expired) && data.expired > now
}
