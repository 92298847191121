import { default as activationgYsbfIeAkPMeta } from "/project/pages/activation.vue?macro=true";
import { default as indexC9VU2rTV2IMeta } from "/project/pages/auth/index.vue?macro=true";
import { default as index9HCIah9pSRMeta } from "/project/pages/battlepass/[id]/index.vue?macro=true";
import { default as indexGJ4dw6qZYAMeta } from "/project/pages/battlepass/index.vue?macro=true";
import { default as callbackI7cf9WIBuGMeta } from "/project/pages/callback.vue?macro=true";
import { default as indexa4VTrf9bg0Meta } from "/project/pages/clans/[tag]/index.vue?macro=true";
import { default as createkiqMYjxcl9Meta } from "/project/pages/clans/create.vue?macro=true";
import { default as indexW7UuSrynHLMeta } from "/project/pages/clans/index.vue?macro=true";
import { default as _91token_93uK1ccSIPurMeta } from "/project/pages/clans/join/[token].vue?macro=true";
import { default as membersrizwbPtHqWMeta } from "/project/pages/clans/members.vue?macro=true";
import { default as ratingWlDpj4NQ6hMeta } from "/project/pages/clans/rating.vue?macro=true";
import { default as settingsGE1KdRL5w5Meta } from "/project/pages/clans/settings.vue?macro=true";
import { default as indexDnfX7BYdQdMeta } from "/project/pages/fantasy/collection/index.vue?macro=true";
import { default as indexFt9KmJAq2uMeta } from "/project/pages/fantasy/index.vue?macro=true";
import { default as indexrM0Rh2s5LIMeta } from "/project/pages/fantasy/shop/index.vue?macro=true";
import { default as indexxsgXkFeqVxMeta } from "/project/pages/faq/index.vue?macro=true";
import { default as index5GDnY66y5IMeta } from "/project/pages/index.vue?macro=true";
import { default as accountaHWRRA5XAMMeta } from "/project/pages/m/account.vue?macro=true";
import { default as bonushwLFdMUbbeMeta } from "/project/pages/m/bonus.vue?macro=true";
import { default as clanRJblsNe8BbMeta } from "/project/pages/m/clan.vue?macro=true";
import { default as codeaRmKMZUFtrMeta } from "/project/pages/m/code.vue?macro=true";
import { default as leaderboardwRTA0VHAraMeta } from "/project/pages/m/leaderboard.vue?macro=true";
import { default as newsm47sC84CfSMeta } from "/project/pages/m/news.vue?macro=true";
import { default as notify6GVK1bVygYMeta } from "/project/pages/m/notify.vue?macro=true";
import { default as purchasesnwWdNzvys4Meta } from "/project/pages/m/purchases.vue?macro=true";
import { default as _enmHyM7cCEcDMeta } from "/project/pages/pages/steam/_en.js?macro=true";
import { default as _ruv8PBWz1M6JMeta } from "/project/pages/pages/steam/_ru.js?macro=true";
import { default as indexiXpv5wvEYPMeta } from "/project/pages/pages/steam/index.vue?macro=true";
import { default as indexCtNj3BG0KGMeta } from "/project/pages/pickem/index.vue?macro=true";
import { default as tournamentcq9VKfL7o5Meta } from "/project/pages/pickem/tournament.vue?macro=true";
import { default as achievementsWSh7WboHIKMeta } from "/project/pages/profile/[id]/achievements.vue?macro=true";
import { default as indexW0O1dDDZxWMeta } from "/project/pages/profile/[id]/index.vue?macro=true";
import { default as indexyxOugkp29aMeta } from "/project/pages/profile/index.vue?macro=true";
import { default as indexLWni1nomVxMeta } from "/project/pages/profile/settings/index/index.vue?macro=true";
import { default as prizes6CLP6auWskMeta } from "/project/pages/profile/settings/index/prizes.vue?macro=true";
import { default as referralshvfkg53iUTMeta } from "/project/pages/profile/settings/index/referrals.vue?macro=true";
import { default as taskCSreOZsFcwMeta } from "/project/pages/profile/settings/index/task.vue?macro=true";
import { default as indexpdh8K3Ui9uMeta } from "/project/pages/profile/settings/index.vue?macro=true";
import { default as indexKvRsBRjJ4JMeta } from "/project/pages/rules/index.vue?macro=true";
import { default as _91category_93V2ztLba1RfMeta } from "/project/pages/shop/index/[category].vue?macro=true";
import { default as indexvt5dghFjiOMeta } from "/project/pages/shop/index/index.vue?macro=true";
import { default as indexFTF1Ld1gczMeta } from "/project/pages/shop/index.vue?macro=true";
import { default as indexvDv82UwE3bMeta } from "/project/pages/tasks/index.vue?macro=true";
import { default as indexjnDXZDPmgoMeta } from "/project/app-modules/pixelbattle/pages/index.vue?macro=true";
export default [
  {
    name: activationgYsbfIeAkPMeta?.name ?? "activation___ru",
    path: activationgYsbfIeAkPMeta?.path ?? "/activation",
    meta: activationgYsbfIeAkPMeta || {},
    alias: activationgYsbfIeAkPMeta?.alias || [],
    redirect: activationgYsbfIeAkPMeta?.redirect,
    component: () => import("/project/pages/activation.vue").then(m => m.default || m)
  },
  {
    name: indexC9VU2rTV2IMeta?.name ?? "auth___ru",
    path: indexC9VU2rTV2IMeta?.path ?? "/auth",
    meta: indexC9VU2rTV2IMeta || {},
    alias: indexC9VU2rTV2IMeta?.alias || [],
    redirect: indexC9VU2rTV2IMeta?.redirect,
    component: () => import("/project/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: index9HCIah9pSRMeta?.name ?? "battlepass-id___ru",
    path: index9HCIah9pSRMeta?.path ?? "/battlepass/:id()",
    meta: index9HCIah9pSRMeta || {},
    alias: index9HCIah9pSRMeta?.alias || [],
    redirect: index9HCIah9pSRMeta?.redirect,
    component: () => import("/project/pages/battlepass/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexGJ4dw6qZYAMeta?.name ?? "battlepass___ru",
    path: indexGJ4dw6qZYAMeta?.path ?? "/battlepass",
    meta: indexGJ4dw6qZYAMeta || {},
    alias: indexGJ4dw6qZYAMeta?.alias || [],
    redirect: indexGJ4dw6qZYAMeta?.redirect,
    component: () => import("/project/pages/battlepass/index.vue").then(m => m.default || m)
  },
  {
    name: callbackI7cf9WIBuGMeta?.name ?? "callback___ru",
    path: callbackI7cf9WIBuGMeta?.path ?? "/callback",
    meta: callbackI7cf9WIBuGMeta || {},
    alias: callbackI7cf9WIBuGMeta?.alias || [],
    redirect: callbackI7cf9WIBuGMeta?.redirect,
    component: () => import("/project/pages/callback.vue").then(m => m.default || m)
  },
  {
    name: indexa4VTrf9bg0Meta?.name ?? "clans-tag___ru",
    path: indexa4VTrf9bg0Meta?.path ?? "/clans/:tag()",
    meta: indexa4VTrf9bg0Meta || {},
    alias: indexa4VTrf9bg0Meta?.alias || [],
    redirect: indexa4VTrf9bg0Meta?.redirect,
    component: () => import("/project/pages/clans/[tag]/index.vue").then(m => m.default || m)
  },
  {
    name: createkiqMYjxcl9Meta?.name ?? "clans-create___ru",
    path: createkiqMYjxcl9Meta?.path ?? "/clans/create",
    meta: createkiqMYjxcl9Meta || {},
    alias: createkiqMYjxcl9Meta?.alias || [],
    redirect: createkiqMYjxcl9Meta?.redirect,
    component: () => import("/project/pages/clans/create.vue").then(m => m.default || m)
  },
  {
    name: indexW7UuSrynHLMeta?.name ?? "clans___ru",
    path: indexW7UuSrynHLMeta?.path ?? "/clans",
    meta: indexW7UuSrynHLMeta || {},
    alias: indexW7UuSrynHLMeta?.alias || [],
    redirect: indexW7UuSrynHLMeta?.redirect,
    component: () => import("/project/pages/clans/index.vue").then(m => m.default || m)
  },
  {
    name: _91token_93uK1ccSIPurMeta?.name ?? "clans-join-token___ru",
    path: _91token_93uK1ccSIPurMeta?.path ?? "/clans/join/:token()",
    meta: _91token_93uK1ccSIPurMeta || {},
    alias: _91token_93uK1ccSIPurMeta?.alias || [],
    redirect: _91token_93uK1ccSIPurMeta?.redirect,
    component: () => import("/project/pages/clans/join/[token].vue").then(m => m.default || m)
  },
  {
    name: membersrizwbPtHqWMeta?.name ?? "clans-members___ru",
    path: membersrizwbPtHqWMeta?.path ?? "/clans/members",
    meta: membersrizwbPtHqWMeta || {},
    alias: membersrizwbPtHqWMeta?.alias || [],
    redirect: membersrizwbPtHqWMeta?.redirect,
    component: () => import("/project/pages/clans/members.vue").then(m => m.default || m)
  },
  {
    name: ratingWlDpj4NQ6hMeta?.name ?? "clans-rating___ru",
    path: ratingWlDpj4NQ6hMeta?.path ?? "/clans/rating",
    meta: ratingWlDpj4NQ6hMeta || {},
    alias: ratingWlDpj4NQ6hMeta?.alias || [],
    redirect: ratingWlDpj4NQ6hMeta?.redirect,
    component: () => import("/project/pages/clans/rating.vue").then(m => m.default || m)
  },
  {
    name: settingsGE1KdRL5w5Meta?.name ?? "clans-settings___ru",
    path: settingsGE1KdRL5w5Meta?.path ?? "/clans/settings",
    meta: settingsGE1KdRL5w5Meta || {},
    alias: settingsGE1KdRL5w5Meta?.alias || [],
    redirect: settingsGE1KdRL5w5Meta?.redirect,
    component: () => import("/project/pages/clans/settings.vue").then(m => m.default || m)
  },
  {
    name: indexDnfX7BYdQdMeta?.name ?? "fantasy-collection___ru",
    path: indexDnfX7BYdQdMeta?.path ?? "/fantasy/collection",
    meta: indexDnfX7BYdQdMeta || {},
    alias: indexDnfX7BYdQdMeta?.alias || [],
    redirect: indexDnfX7BYdQdMeta?.redirect,
    component: () => import("/project/pages/fantasy/collection/index.vue").then(m => m.default || m)
  },
  {
    name: indexFt9KmJAq2uMeta?.name ?? "fantasy___ru",
    path: indexFt9KmJAq2uMeta?.path ?? "/fantasy",
    meta: indexFt9KmJAq2uMeta || {},
    alias: indexFt9KmJAq2uMeta?.alias || [],
    redirect: indexFt9KmJAq2uMeta?.redirect,
    component: () => import("/project/pages/fantasy/index.vue").then(m => m.default || m)
  },
  {
    name: indexrM0Rh2s5LIMeta?.name ?? "fantasy-shop___ru",
    path: indexrM0Rh2s5LIMeta?.path ?? "/fantasy/shop",
    meta: indexrM0Rh2s5LIMeta || {},
    alias: indexrM0Rh2s5LIMeta?.alias || [],
    redirect: indexrM0Rh2s5LIMeta?.redirect,
    component: () => import("/project/pages/fantasy/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexxsgXkFeqVxMeta?.name ?? "faq___ru",
    path: indexxsgXkFeqVxMeta?.path ?? "/faq",
    meta: indexxsgXkFeqVxMeta || {},
    alias: indexxsgXkFeqVxMeta?.alias || [],
    redirect: indexxsgXkFeqVxMeta?.redirect,
    component: () => import("/project/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: index5GDnY66y5IMeta?.name ?? "index___ru",
    path: index5GDnY66y5IMeta?.path ?? "/",
    meta: index5GDnY66y5IMeta || {},
    alias: index5GDnY66y5IMeta?.alias || [],
    redirect: index5GDnY66y5IMeta?.redirect,
    component: () => import("/project/pages/index.vue").then(m => m.default || m)
  },
  {
    name: accountaHWRRA5XAMMeta?.name ?? "m-account___ru",
    path: accountaHWRRA5XAMMeta?.path ?? "/m/account",
    meta: accountaHWRRA5XAMMeta || {},
    alias: accountaHWRRA5XAMMeta?.alias || [],
    redirect: accountaHWRRA5XAMMeta?.redirect,
    component: () => import("/project/pages/m/account.vue").then(m => m.default || m)
  },
  {
    name: bonushwLFdMUbbeMeta?.name ?? "m-bonus___ru",
    path: bonushwLFdMUbbeMeta?.path ?? "/m/bonus",
    meta: bonushwLFdMUbbeMeta || {},
    alias: bonushwLFdMUbbeMeta?.alias || [],
    redirect: bonushwLFdMUbbeMeta?.redirect,
    component: () => import("/project/pages/m/bonus.vue").then(m => m.default || m)
  },
  {
    name: clanRJblsNe8BbMeta?.name ?? "m-clan___ru",
    path: clanRJblsNe8BbMeta?.path ?? "/m/clan",
    meta: clanRJblsNe8BbMeta || {},
    alias: clanRJblsNe8BbMeta?.alias || [],
    redirect: clanRJblsNe8BbMeta?.redirect,
    component: () => import("/project/pages/m/clan.vue").then(m => m.default || m)
  },
  {
    name: codeaRmKMZUFtrMeta?.name ?? "m-code___ru",
    path: codeaRmKMZUFtrMeta?.path ?? "/m/code",
    meta: codeaRmKMZUFtrMeta || {},
    alias: codeaRmKMZUFtrMeta?.alias || [],
    redirect: codeaRmKMZUFtrMeta?.redirect,
    component: () => import("/project/pages/m/code.vue").then(m => m.default || m)
  },
  {
    name: leaderboardwRTA0VHAraMeta?.name ?? "m-leaderboard___ru",
    path: leaderboardwRTA0VHAraMeta?.path ?? "/m/leaderboard",
    meta: leaderboardwRTA0VHAraMeta || {},
    alias: leaderboardwRTA0VHAraMeta?.alias || [],
    redirect: leaderboardwRTA0VHAraMeta?.redirect,
    component: () => import("/project/pages/m/leaderboard.vue").then(m => m.default || m)
  },
  {
    name: newsm47sC84CfSMeta?.name ?? "m-news___ru",
    path: newsm47sC84CfSMeta?.path ?? "/m/news",
    meta: newsm47sC84CfSMeta || {},
    alias: newsm47sC84CfSMeta?.alias || [],
    redirect: newsm47sC84CfSMeta?.redirect,
    component: () => import("/project/pages/m/news.vue").then(m => m.default || m)
  },
  {
    name: notify6GVK1bVygYMeta?.name ?? "m-notify___ru",
    path: notify6GVK1bVygYMeta?.path ?? "/m/notify",
    meta: notify6GVK1bVygYMeta || {},
    alias: notify6GVK1bVygYMeta?.alias || [],
    redirect: notify6GVK1bVygYMeta?.redirect,
    component: () => import("/project/pages/m/notify.vue").then(m => m.default || m)
  },
  {
    name: purchasesnwWdNzvys4Meta?.name ?? "m-purchases___ru",
    path: purchasesnwWdNzvys4Meta?.path ?? "/m/purchases",
    meta: purchasesnwWdNzvys4Meta || {},
    alias: purchasesnwWdNzvys4Meta?.alias || [],
    redirect: purchasesnwWdNzvys4Meta?.redirect,
    component: () => import("/project/pages/m/purchases.vue").then(m => m.default || m)
  },
  {
    name: _enmHyM7cCEcDMeta?.name ?? "pages-steam-_en___ru",
    path: _enmHyM7cCEcDMeta?.path ?? "/pages/steam/_en",
    meta: _enmHyM7cCEcDMeta || {},
    alias: _enmHyM7cCEcDMeta?.alias || [],
    redirect: _enmHyM7cCEcDMeta?.redirect,
    component: () => import("/project/pages/pages/steam/_en.js").then(m => m.default || m)
  },
  {
    name: _ruv8PBWz1M6JMeta?.name ?? "pages-steam-_ru___ru",
    path: _ruv8PBWz1M6JMeta?.path ?? "/pages/steam/_ru",
    meta: _ruv8PBWz1M6JMeta || {},
    alias: _ruv8PBWz1M6JMeta?.alias || [],
    redirect: _ruv8PBWz1M6JMeta?.redirect,
    component: () => import("/project/pages/pages/steam/_ru.js").then(m => m.default || m)
  },
  {
    name: indexiXpv5wvEYPMeta?.name ?? "pages-steam___ru",
    path: indexiXpv5wvEYPMeta?.path ?? "/pages/steam",
    meta: indexiXpv5wvEYPMeta || {},
    alias: indexiXpv5wvEYPMeta?.alias || [],
    redirect: indexiXpv5wvEYPMeta?.redirect,
    component: () => import("/project/pages/pages/steam/index.vue").then(m => m.default || m)
  },
  {
    name: indexCtNj3BG0KGMeta?.name ?? "pickem___ru",
    path: indexCtNj3BG0KGMeta?.path ?? "/pickem",
    meta: indexCtNj3BG0KGMeta || {},
    alias: indexCtNj3BG0KGMeta?.alias || [],
    redirect: indexCtNj3BG0KGMeta?.redirect,
    component: () => import("/project/pages/pickem/index.vue").then(m => m.default || m)
  },
  {
    name: tournamentcq9VKfL7o5Meta?.name ?? "pickem-tournament___ru",
    path: tournamentcq9VKfL7o5Meta?.path ?? "/pickem/tournament",
    meta: tournamentcq9VKfL7o5Meta || {},
    alias: tournamentcq9VKfL7o5Meta?.alias || [],
    redirect: tournamentcq9VKfL7o5Meta?.redirect,
    component: () => import("/project/pages/pickem/tournament.vue").then(m => m.default || m)
  },
  {
    name: achievementsWSh7WboHIKMeta?.name ?? "profile-id-achievements___ru",
    path: achievementsWSh7WboHIKMeta?.path ?? "/profile/:id()/achievements",
    meta: achievementsWSh7WboHIKMeta || {},
    alias: achievementsWSh7WboHIKMeta?.alias || [],
    redirect: achievementsWSh7WboHIKMeta?.redirect,
    component: () => import("/project/pages/profile/[id]/achievements.vue").then(m => m.default || m)
  },
  {
    name: indexW0O1dDDZxWMeta?.name ?? "profile-id___ru",
    path: indexW0O1dDDZxWMeta?.path ?? "/profile/:id()",
    meta: indexW0O1dDDZxWMeta || {},
    alias: indexW0O1dDDZxWMeta?.alias || [],
    redirect: indexW0O1dDDZxWMeta?.redirect,
    component: () => import("/project/pages/profile/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexyxOugkp29aMeta?.name ?? "profile___ru",
    path: indexyxOugkp29aMeta?.path ?? "/profile",
    meta: indexyxOugkp29aMeta || {},
    alias: indexyxOugkp29aMeta?.alias || [],
    redirect: indexyxOugkp29aMeta?.redirect,
    component: () => import("/project/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexpdh8K3Ui9uMeta?.name ?? undefined,
    path: indexpdh8K3Ui9uMeta?.path ?? "/profile/settings",
    meta: indexpdh8K3Ui9uMeta || {},
    alias: indexpdh8K3Ui9uMeta?.alias || [],
    redirect: indexpdh8K3Ui9uMeta?.redirect,
    component: () => import("/project/pages/profile/settings/index.vue").then(m => m.default || m),
    children: [
  {
    name: indexLWni1nomVxMeta?.name ?? "profile-settings-index___ru",
    path: indexLWni1nomVxMeta?.path ?? "",
    meta: indexLWni1nomVxMeta || {},
    alias: indexLWni1nomVxMeta?.alias || [],
    redirect: indexLWni1nomVxMeta?.redirect,
    component: () => import("/project/pages/profile/settings/index/index.vue").then(m => m.default || m)
  },
  {
    name: prizes6CLP6auWskMeta?.name ?? "profile-settings-index-prizes___ru",
    path: prizes6CLP6auWskMeta?.path ?? "prizes",
    meta: prizes6CLP6auWskMeta || {},
    alias: prizes6CLP6auWskMeta?.alias || [],
    redirect: prizes6CLP6auWskMeta?.redirect,
    component: () => import("/project/pages/profile/settings/index/prizes.vue").then(m => m.default || m)
  },
  {
    name: referralshvfkg53iUTMeta?.name ?? "profile-settings-index-referrals___ru",
    path: referralshvfkg53iUTMeta?.path ?? "referrals",
    meta: referralshvfkg53iUTMeta || {},
    alias: referralshvfkg53iUTMeta?.alias || [],
    redirect: referralshvfkg53iUTMeta?.redirect,
    component: () => import("/project/pages/profile/settings/index/referrals.vue").then(m => m.default || m)
  },
  {
    name: taskCSreOZsFcwMeta?.name ?? "profile-settings-index-task___ru",
    path: taskCSreOZsFcwMeta?.path ?? "task",
    meta: taskCSreOZsFcwMeta || {},
    alias: taskCSreOZsFcwMeta?.alias || [],
    redirect: taskCSreOZsFcwMeta?.redirect,
    component: () => import("/project/pages/profile/settings/index/task.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexKvRsBRjJ4JMeta?.name ?? "rules___ru",
    path: indexKvRsBRjJ4JMeta?.path ?? "/rules",
    meta: indexKvRsBRjJ4JMeta || {},
    alias: indexKvRsBRjJ4JMeta?.alias || [],
    redirect: indexKvRsBRjJ4JMeta?.redirect,
    component: () => import("/project/pages/rules/index.vue").then(m => m.default || m)
  },
  {
    name: indexFTF1Ld1gczMeta?.name ?? undefined,
    path: indexFTF1Ld1gczMeta?.path ?? "/shop",
    meta: indexFTF1Ld1gczMeta || {},
    alias: indexFTF1Ld1gczMeta?.alias || [],
    redirect: indexFTF1Ld1gczMeta?.redirect,
    component: () => import("/project/pages/shop/index.vue").then(m => m.default || m),
    children: [
  {
    name: _91category_93V2ztLba1RfMeta?.name ?? "shop-index-category___ru",
    path: _91category_93V2ztLba1RfMeta?.path ?? ":category()",
    meta: _91category_93V2ztLba1RfMeta || {},
    alias: _91category_93V2ztLba1RfMeta?.alias || [],
    redirect: _91category_93V2ztLba1RfMeta?.redirect,
    component: () => import("/project/pages/shop/index/[category].vue").then(m => m.default || m)
  },
  {
    name: indexvt5dghFjiOMeta?.name ?? "shop-index___ru",
    path: indexvt5dghFjiOMeta?.path ?? "",
    meta: indexvt5dghFjiOMeta || {},
    alias: indexvt5dghFjiOMeta?.alias || [],
    redirect: indexvt5dghFjiOMeta?.redirect,
    component: () => import("/project/pages/shop/index/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexvDv82UwE3bMeta?.name ?? "tasks___ru",
    path: indexvDv82UwE3bMeta?.path ?? "/tasks",
    meta: indexvDv82UwE3bMeta || {},
    alias: indexvDv82UwE3bMeta?.alias || [],
    redirect: indexvDv82UwE3bMeta?.redirect,
    component: () => import("/project/pages/tasks/index.vue").then(m => m.default || m)
  },
  {
    name: indexjnDXZDPmgoMeta?.name ?? "pixelbattle___ru",
    path: indexjnDXZDPmgoMeta?.path ?? "/pixelbattle",
    meta: indexjnDXZDPmgoMeta || {},
    alias: indexjnDXZDPmgoMeta?.alias || [],
    redirect: indexjnDXZDPmgoMeta?.redirect,
    component: () => import("/project/app-modules/pixelbattle/pages/index.vue").then(m => m.default || m)
  }
]