import { reactive, ref } from 'vue'
import type { Conditions, Breakpoints } from './types'

export class Media<T extends Breakpoints> {
  private conditions = reactive<Conditions>({})

  private _enabled = ref(false)
  private _defaultValue = false

  breakpoints: T

  constructor({ breakpoints }: { breakpoints: T }) {
    this.breakpoints = breakpoints
  }

  get isEnabled() {
    return this._enabled.value
  }

  get defaultValue() {
    return this._defaultValue
  }

  test(condition: string, defaultValue = this._defaultValue) {
    if (!this.conditions[condition]) {
      this.conditions[condition] = {
        matchMedia: null,
        result: {
          value: defaultValue
        }
      }

      if (typeof window !== 'undefined') {
        const _matchMedia = matchMedia(condition)
        this.conditions[condition].matchMedia = _matchMedia

        _matchMedia.onchange = event => {
          this.conditions[condition].result.value = event.matches
        }

        this.conditions[condition].result.value = _matchMedia.matches
      }
    }

    return this._enabled.value
      ? this.conditions[condition].result.value
      : defaultValue
  }

  private templateMax(w: number) {
    return `(max-width: ${w + 1}px)`
  }

  private templateMin(w: number) {
    return `(min-width: ${w}px)`
  }

  down(value: keyof T | number, defaultValue?: boolean) {
    return this.test(
      this.templateMax(
        typeof value === 'number' ? value : this.breakpoints[value]
      ),
      defaultValue
    )
  }

  up(value: keyof T | number, defaultValue?: boolean) {
    return this.test(
      this.templateMin(
        typeof value === 'number' ? value : this.breakpoints[value]
      ),
      defaultValue
    )
  }

  max(value: keyof T | number, defaultValue?: boolean) {
    return this.down(value, defaultValue)
  }

  min(value: keyof T | number, defaultValue?: boolean) {
    return this.up(value, defaultValue)
  }

  enable() {
    this._enabled.value = true
  }

  disable() {
    this._enabled.value = false
  }

  setDefaultValue(value: boolean) {
    this._defaultValue = value
  }
}
