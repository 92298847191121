import { defineNuxtPlugin } from '#app'
import { createApp } from 'vue'
import pick from 'lodash/pick'
import IndexTemplate from './components/index.vue'
import { TYPES } from './consts'
import type { PublicInterface } from './types'

export default defineNuxtPlugin(() => {
  const container = document.querySelector('[data-app=true]') || document.body
  const app = createApp(IndexTemplate)
  const element = document.createElement('div')

  container.appendChild(element)

  const instance = app.mount(element) as InstanceType<typeof IndexTemplate>
  const snacks: PublicInterface = pick(instance, TYPES)

  return {
    provide: { snacks }
  }
})
