// https://vuejs.org/guide/reusability/custom-directives.html#directive-hooks

export default function () {
  const watchers = []
  const loopDelay = 50
  let lastLoopExecute = 0
  const execute = (watcher, cb, cbFetch) => {
    if (Date.now() - watcher.data.lastExecuteDate > watcher.options.delay) {
      if (cbFetch) {
        if (['success', 'idle'].includes(cbFetch.status.value)) {
          cbFetch.execute()
        }
      } else {
        cb()
      }
      watcher.data.lastExecuteDate = Date.now()
    }
  }

  function loop() {
    const now = Date.now()
    if (now - loopDelay > lastLoopExecute) {
      watchers.forEach(watcher => {
        if (watcher.data.disabled) {
          return
        }
        const {
          options,
          options: { scrollingElement }
        } = watcher

        const isNearBottom =
          scrollingElement.scrollHeight -
            scrollingElement.scrollTop -
            scrollingElement.clientHeight <
          options.offsetBottom
        const isNearRight =
          scrollingElement.scrollWidth -
            scrollingElement.scrollLeft -
            scrollingElement.clientWidth <
          options.offsetRight
        const isNearLeft = scrollingElement.scrollLeft < options.offsetLeft

        if ((options.onBottom || options.onBottomFetch) && isNearBottom) {
          execute(watcher, options.onBottom, options.onBottomFetch)
        }

        if ((options.onRight || options.onRightFetch) && isNearRight) {
          execute(watcher, options.onRight, options.onRightFetch)
        }

        if ((options.onLeft || options.onLeftFetch) && isNearLeft) {
          execute(watcher, options.onLeft, options.onLeftFetch)
        }
      })
      lastLoopExecute = now
    }
    requestAnimationFrame(loop)
  }

  if (process.client) {
    loop()
  }

  return {
    // called before bound element's attributes
    // or event listeners are applied
    mounted(el, binding, vnode, prevVnode) {
      const id = generateId()
      el.dataset.scrollTriggerId = id

      let scrollingElement = el

      if (binding.value.global) {
        scrollingElement = document.scrollingElement
      } else if (binding.value.selector) {
        const queriedElement = document.querySelector(binding.value.selector)

        if (queriedElement) {
          scrollingElement = queriedElement
        } else if (import.meta.dev) {
          console.warn(
            'Cannot find an element by the selector:',
            binding.value.selector
          )
        }
      }

      const options = Object.assign(
        {
          global: false,
          offsetBottom: 200,
          offsetRight: 200,
          offsetLeft: 200,
          delay: 150,
          onBottom: null,
          onBottomFetch: null,
          onRight: null,
          onRightFetch: null,
          onLeft: null,
          overscroll: true,
          onLeftFetch: null,
          scrollingElement
        },
        binding.value
      )

      if (!options.global && options.overscroll) {
        el.classList.add('overscroll')
      }

      watchers.push({
        id,
        options,
        data: {
          lastExecuteDate: 0,
          disabled: binding.value.disabled ?? false
        }
      })
    },
    // called right before the element is inserted into the DOM.
    beforeMount(el, binding, vnode, prevVnode) {},
    // called when the bound element's parent component
    // and all its children are mounted.
    // called before the parent component is updated
    beforeUpdate(el, binding, vnode, prevVnode) {},
    // called after the parent component and
    // all of its children have updated
    updated(el, binding, vnode, prevVnode) {
      const id = el.dataset?.scrollTriggerId
      if (!id) {
        return
      }
      const watcherIndex = watchers.findIndex(el => el.id === id)
      watchers[watcherIndex].data.disabled = binding.value.disabled

      if (
        !watchers[watcherIndex].options.global &&
        watchers[watcherIndex].options.overscroll
      ) {
        el.classList.add('overscroll')
      } else {
        el.classList.remove('overscroll')
      }
    },
    // called before the parent component is unmounted
    beforeUnmount(el, binding, vnode, prevVnode) {},
    // called when the parent component is unmounted
    unmounted(el, binding, vnode, prevVnode) {
      const id = el.dataset.scrollTriggerId
      const index = watchers.findIndex(item => item.id === id)
      if (index > -1) {
        watchers.splice(index, 1)
      }
      el.classList.remove('overscroll')
    }
  }
}

function generateId() {
  return (Math.random() + 1).toString(36).substring(7)
}
