import appConfig from '@/app.config'

export default defineNuxtRouteMiddleware(async (to, from) => {
  // const headers = useRequestHeaders()
  // console.log('middleware headers', headers)
  // skip middleware on server
  if (process.server) return

  if (appConfig.seasonActive) return
  const { $sdk, $normalizePath } = useNuxtApp()
  const account = await $sdk
    .module('auth')
    .then(({ getAccount }) => getAccount())

  if (to.path !== '/' && !account?.scopes?.includes('accessTesting')) {
    const redirectPath = $normalizePath('/')
    return navigateTo(redirectPath)
  }
})
