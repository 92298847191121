<template>
  <component @click="onClick" :is="args.is" v-bind="args">
    <ui-spinner
      :class="$style.spinner"
      v-if="loading"
      size="2"
      :theme="spinnerTheme"
    />
    <ui-icon v-if="icon && !loading" :class="$style.icon" :name="icon" />
    <span :class="$style.text"><slot /></span>
  </component>
</template>

<script>
export default {
  name: 'UiButton'
}
</script>
<script setup>
import { useCssModule, computed } from 'vue'
import UiSpinner from '../spinner/index.vue'
import UiIcon from '../icon/index.vue'
import { normalizePath } from '@/_plugins/i18n/utils'

const style = useCssModule()
const emit = defineEmits(['click'])
const props = defineProps({
  href: {
    type: String,
    default: ''
  },
  to: {
    type: String,
    default: ''
  },
  tag: {
    type: String,
    default: 'button'
  },
  block: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  },
  uppercase: {
    type: Boolean,
    default: false
  },
  size: {
    type: String,
    default: 'small',
    validator(value) {
      return ['small', 'medium', 'large'].includes(value)
    }
  },
  active: {
    type: Boolean,
    default: false
  },
  fill: {
    type: Boolean,
    default: false
  },
  theme: {
    type: String,
    default: 'primary',
    validator(value) {
      return [
        'primary',
        'accent',
        'accent-alt',
        'warn',
        'classic',
        'gray',
        'clear',
        'black',
        'ui'
      ].includes(value)
    }
  },
  icon: {
    type: String,
    default: ''
  }
})

const args = computed(() => {
  const args = {
    class: classes.value,
    is: props.tag
  }
  if (props.href) {
    args.target = '_blank'
    args.rel = 'noopener'
    args.href = props.href
    args.is = 'a'
  } else if (props.to) {
    args.to = routeTo.value
    args.is = 'router-link'
  }
  return args
})

const classes = computed(() => [
  style.button,
  { [style[props.theme]]: props.theme },
  { [style[props.size]]: props.size },
  props.disabled && style.disabled,
  props.loading && style.loading,
  props.block && style.block,
  props.fill && style.fill,
  props.active && style.active,
  props.uppercase && style.uppercase
])

const spinnerTheme = computed(() => {
  if (props.theme === 'primary') {
    if (props.fill) {
      return 'primary'
    } else {
      return 'light'
    }
  } else if (props.theme === 'accent') {
    return 'primary'
  } else {
    return 'light'
  }
})

const routeTo = computed(() => normalizePath(props.to))

const onClick = event => {
  if (!props.disabled && !props.loading) {
    emit('click', event)
  }
}
</script>

<style lang="scss" module>
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  text-align: center;
  transition: all 0.2s;
  position: relative;
  user-select: none;
  border-radius: 0.7em;
  cursor: pointer;
}
.block {
  width: 100%;
  justify-content: center;
}
.text {
  font-style: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.disabled {
  pointer-events: none;
}

.uppercase {
  text-transform: uppercase;
}

.small {
  padding: 0.8em 1em;
  border-radius: 0.7em;

  .spinner {
    margin-right: 1em;
  }
  .icon {
    margin-right: 0.6em;
    width: 1.6em;
    height: 1.6em;
  }
  .text {
    font-size: 1.4em;
    line-height: 143%;
    letter-spacing: -0.02em;
  }
}

.medium {
  padding: 1.6em 3.2em;
  border-radius: 0.6em;
  font-weight: 700;
  @include down(sm) {
    padding: 1em 3em;
  }
  .spinner {
    margin-right: 2em;
  }
  .icon {
    margin-right: 0.6em;
    width: 1.8em;
    height: 1.8em;
    @include down(sm) {
      width: 1.6em;
      height: 1.6em;
    }
  }
  .text {
    font-size: 1.6em;
    line-height: 125%;
    letter-spacing: -0.02em;

    @include down(sm) {
      font-size: 1.4em;
      line-height: 143%;
    }
  }
}
.large {
  padding: 2em 3.2em;
  border-radius: 0.6em;
  @include down(sm) {
    padding: 1.6em 3.2em;
  }
  .spinner {
    margin-right: 2em;
  }
  .icon {
    margin-right: 2em;
    width: 2.1em;
    height: 2.1em;
  }
  .text {
    font-size: 1.6em;
    line-height: 125%;
    letter-spacing: -0.02em;
  }
}

.accent {
  background: transparent;
  color: var(--app-color-black);
  font-style: normal;
  font-weight: 600;
  &.active,
  &:hover {
    background-color: #00c7b1;
  }
  &.fill {
    background-color: var(--app-active-color);
    &.active,
    &:hover {
      background-color: darken(#00bfa8, 10%);
    }
  }

  &.disabled {
    background: #acb0d5 !important;
    color: rgba(0, 0, 0, 0.4) !important;
  }
}

.accent-alt {
  color: var(--app-color-text);
  font-weight: 600;

  &.active,
  &:hover,
  &.fill {
    background-color: #753bbd;
  }

  &.fill {
    &.active,
    &:hover {
      background-color: darken(#753bbd, 10%);
    }
  }

  &.disabled {
    background: #acb0d5;
    color: rgba(0, 0, 0, 0.4);
  }
}

.primary {
  background: transparent;
  border: 0.1em solid #acb0d5;
  color: #acb0d5;
  &.active,
  &:hover {
    background-color: #acb0d5;
    color: #0a0e29;
  }
  &.fill {
    background-color: #acb0d5;
    color: #0a0e29;
    &.active,
    &:hover {
      background-color: lighten(#acb0d5, 10%);
    }
  }
}

.warn {
  background: transparent;
  color: #ff1569;
  &.active,
  &:hover {
    background-color: #ff1569;
    color: #fff;
  }
  &.fill {
    color: #fff;
    background: #ff1569;
    &.active,
    &:hover {
      background: lighten(#ff1569, 10%);
    }
  }
}

.classic {
  background: transparent;
  color: rgba(#ffffff, 50%);
  border: 1px solid rgba(#ffffff, 50%);
  &.active,
  &:hover {
    background-color: rgba(#ffffff, 50%);
    color: #221f3a;
  }
  &.fill {
    border: 0 none;
    color: #ffffff;
    background: var(--t-app-div-color);
    &.active,
    &:hover {
      background: lighten(#1a202f, 10%);
    }
  }
  &.disabled {
    opacity: 0.4;
  }
}

.gray {
  background: transparent;
  color: #acb0d5;
  border: 1px solid #282b3e;
  &.active,
  &:hover {
    background-color: rgba(#282b3e, 50%);
    color: #acb0d5;
  }
  &.active,
  &.fill {
    border: 0 none;
    color: #acb0d5;
    background: #3a4151;
    &:hover {
      background: lighten(#3a4151, 10%);
    }
  }
}

.ui {
  background: transparent;
  color: #7e8197;
  &.active,
  &:global(.router-link-exact-active),
  &:hover {
    background-color: #2f3648;
    color: #fff;
  }
  &.fill {
    border: 0 none;
    color: rgba(#fff, 0.4);
    background: #252c3e;
    &.active,
    &:global(.router-link-exact-active),
    &:hover {
      color: #fff;
      background: #2f3648;
    }
  }
}

.black {
  background: transparent;
  color: #ffffff;
  border: 1px solid #000000;
  &.active,
  &:hover {
    background-color: rgba(#000000, 50%);
    color: #ffffff;
  }
  &.fill {
    border: 0 none;
    background: #000000;
    &.active,
    &:hover {
      background: lighten(#000000, 10%);
    }
  }
}

.clear {
  background: transparent;
  color: #ffffff;
  &.active,
  &:hover {
    color: #ffffff;
  }
  &.fill {
    border: 0 none;
    color: #ffffff;
  }
}
</style>
