export const options = { showStepsCounter: false }
export const config = [
  {
    size: 'medium',
    scroll: { lock: true },
    delay: 1000,
    className: 'app-guide-text',
    overlay: {
      pointerEvents: 'all'
      // padding: 20,
    },
    title: 'Добро пожаловать в PARI Pass!',
    text: 'Это интерактивный ивент, где ты можешь зарабатывать игровые баллы и тратить их на крутые призы. Сейчас мы быстро объясним тебе, как он устроен!',
    amplitude: [
      'popup_open',
      {
        popup_name: 'app_guide',
        pop_text: 'Добро пожаловать в PARI Pass!'
      }
    ],
    buttons: [
      {
        text: 'Погнали',
        theme: 'accent',
        amplitude: [
          'button_click',
          {
            popup_name: 'app_guide',
            button_name: 'go',
            button_text: 'Погнали',
            pop_text: 'Добро пожаловать в PARI Pass!'
          }
        ]
      },
      {
        text: 'Пропустить знакомство',
        emit: 'skip',
        amplitude: [
          'button_click',
          {
            popup_name: 'app_guide',
            button_name: 'pass',
            button_text: 'Пропустить знакомство',
            pop_text: 'Добро пожаловать в PARI Pass!'
          }
        ]
      }
    ]
  },
  {
    size: 'medium',
    element: '[data-menu-id="tasks"]',
    scroll: { lock: true },
    className: 'app-guide-tasks',
    overlay: {
      pointerEvents: 'all',
      padding: 20
    },
    offset: { top: 20 },
    text: 'На этой странице ты видишь список всех доступных для выполнения заданий. Выполняй задания – получай PARI Coins, все просто.',
    buttons: [
      { text: 'Понял, дальше', theme: 'accent' },
      { text: 'Пропустить знакомство', emit: 'skip' }
    ]
  },
  {
    size: 'medium',
    element: '#header-account-coins',
    className: 'app-guide-coins',
    overlay: {
      pointerEvents: 'all'
      // padding: 20,
    },
    text: 'Как заработать – разобрались. Теперь смотри, где будет отображаться твой баланс PARI Coins. Что такое Legacy Coins ты узнаешь позже ;-)',
    buttons: [
      { text: 'Отлично, дальше', theme: 'accent' },
      { text: 'Пропустить знакомство', emit: 'skip' }
    ]
  },
  // {
  //   size: 'medium',
  //   element: '#tab-code',
  //   position: 'left',
  //   className: 'app-guide-tab-code',
  //   overlay: {
  //     pointerEvents: 'all'
  //     // padding: 20,
  //   },
  //   text: 'Это меню кодов. Сюда ты сможешь ввести секретный код. Тут же можно получить подсказки о кодах: где они спрятаны и как их найти. За каждый введенный код ты получишь PARI Coins.',
  //   buttons: [
  //     { text: 'Ага, коды, понятно', theme: 'accent' },
  //     { text: 'Пропустить знакомство', emit: 'skip' }
  //   ]
  // },
  {
    size: 'medium',
    element: '[data-menu-id="profile"]',
    className: 'app-guide-menu-profile',
    overlay: {
      pointerEvents: 'all',
      padding: 20
    },
    offset: { top: 20 },
    text: 'Ну и на последок – твой профиль. В профиле спрятаны основные настройки твоего аккаунта. Тебе стоит сюда заглянуть, чтобы полноценно участвовать в ивенте.',
    buttons: [
      { text: 'Понял-понял', theme: 'accent' },
      { text: 'Пропустить знакомство', emit: 'skip' }
    ]
  },
  {
    size: 'medium',
    element: '#aside-footer-menu',
    position: 'left',
    offset: { top: -30 },
    className: 'app-guide-support',
    overlay: {
      pointerEvents: 'all'
      // padding: 20,
    },
    text: 'Удачи в ивенте! Если что – пиши в саппорт!',
    buttons: [{ text: 'Пока', theme: 'accent' }]
  }
]
