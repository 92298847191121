<template>
  <span>
    <document-content
      v-for="(item, i) in source.items"
      :key="i"
      :source="item"
    />
  </span>
</template>

<script>
export default {
  name: 'DocumentContentTexts',
  components: {},
  props: {
    source: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" module></style>
