export default function (
  value: number | string | null | undefined,
  currency = '',
  separator = ' '
) {
  value = value || 0
  const number = value
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + separator)
  return `${number}` + (currency ? ` ${currency.toUpperCase()}` : '')
}
