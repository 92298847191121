import { createSimpleStore } from '~/utils/pinia/light-simple-store'

export const useClansSettingsStore = createSimpleStore('clans-settings', {
  getRequests: async () => {
    const { $sdk } = useNuxtApp()
    const { getSettings } = await $sdk.module('clans')

    return { get: getSettings }
  }
})
