export function numerate(variants, count = 0) {
  let result = ''
  const first = variants[0] || ''
  const second = variants[1] || ''
  const third = variants[2] || second
  const empty = variants[3] || third
  const _count = Math.abs(count)

  if (_count.toString().includes('.')) {
    result = second
  } else if (!_count) {
    result = empty
  } else if (_count % 10 === 1 && _count % 100 !== 11) {
    result = first
  } else if (
    _count % 10 >= 2 &&
    _count % 10 <= 4 &&
    (_count % 100 < 10 || _count % 100 >= 20)
  ) {
    result = second
  } else {
    result = third
  }
  return result
}
