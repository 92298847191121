import type { AmplitudeArgs, GtmArgs } from './types'

export function amplitude(...[event, payload = {}]: AmplitudeArgs) {
  const nuxtApp = useNuxtApp()

  try {
    const instance =
      process.client && window.amplitude ? window.amplitude.getInstance() : null
    if (instance) {
      const userId = nuxtApp.$auth?.account?.id
      if (userId) {
        const coins: Record<string, number> =
          nuxtApp.$auth.account.coins.reduce(
            (
              acc: Record<string, number>,
              el: { code: string; balance: number }
            ) => {
              acc['coin_' + el.code] = el.balance
              return acc
            },
            {}
          )
        // todo Является запросом, а потому отправляется на каждый ивент
        instance.setUserId(userId)
        instance.setUserProperties(coins)
      }

      instance.logEvent(event, {
        ...payload,
        url: window.location.toString(),
        page_name: nuxtApp.$router.currentRoute.value.path
      })
    }
  } catch {}
}

export function gtm(
  ...[event, eventCategory, eventAction = '', eventContent = '']: GtmArgs
) {
  const data = {
    event,
    eventCategory,
    eventAction,
    eventContent
  }
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(data)
}
